import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import Header from "./Header";
import { TEXT_MESSAGES } from "../const";
import PheonixTextField from "../Components/PheonixTextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { GET_ALL_CUSTOMERS } from "../graphql/query";
import { ADD_INVOICE_MUTATION } from "../graphql/mutation";
import SnackBar from "../Components/PheonixSnackBar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import share from "../assets/share.png";
import dayjs from "dayjs";
import { api } from "../api";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import QuoteData from "../interfaces/PheonixQuotation";
import QuoteTable from "../Components/QuoteTable";
import { RowData } from "../interfaces/PheonixProductTableprops";

const initialQuoteData: QuoteData = {
  "Expiry Date": "",
  "Quotation Date": "",
  "Product Plan": "",
  "Customer Notes": "",
  Address: "",
  Amount: "",
  "Customer Name": "",
  Email: "",
  "GST Number": "",
  "Phone Number": "",
  "Customer Id": "",
  "GST Type": "",
  "Quotation Notes": "",
  "HSN Number": "",
};
interface CustomerOption {
  id: string;
  name: string;
  email?: string;
  number?: string;
  gst_no?: string;
  address?: string;
  customerNo?:string;
}
interface ProductOrService {
  name: string | null;
  tax: string | null;
  amount: string | null;
  rate: string | null;
  quantity: string | null;
}
const customer: CustomerOption = {
  id: "",
  name: "",
  email: "",
  number: "",
  gst_no: "",
  address: "",
  customerNo:""
};
const NewQuotation: React.FC = () => {
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [quotationDate, setQuotationDate] = useState<Date | null>(null);
  const [status, setStatus] = React.useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState("");
  const [quotesData, setQuotesData] = useState<QuoteData>(initialQuoteData);
  const [fieldErrors, setFieldErrors] = useState<QuoteData>(initialQuoteData);
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([
    customer,
  ]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const editQuote = location.state?.editQuote || null;
  const [rows, setRows] = useState<RowData[]>([
    {
      "Product Details": "",
      Quantity: "",
      Rate: "",
      Tax: "",
      Amount: "",
    },
  ]);
  type FieldErrors = {
    [index: string]: {
      [fieldName: string]: string;
    };
  };
  const [tableFieldErrors, setTableFieldErrors] = useState<FieldErrors>({});

  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [confirmDisable, setConfirmDisable] = useState(false);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);
  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const areAllFieldsHasValues = (data: QuoteData) => {
    return Object.values(data).every((value) => value !== "");
  };
  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axios.post(api.baseUrl, {
        query: GET_ALL_CUSTOMERS,
      });
      const customers = response.data.data.getAllUsers;
      const customersClient = customers.filter(
        (customer: any) => customer.user_type === "2"
      );
      if (customers) {
        const customerOptions = customersClient.map(
          (customer: {
            _id: string;
            customer_no:string;
            basic_info: {
              firstname: string;
              email: string;
              phone_number: string;
              shipping_address: { address_line1: string };
            };
            billing_info: { GST_no: string };
          }) => ({
            id: customer._id,
            name: customer.basic_info.firstname,
            email: customer.basic_info.email,
            number: customer.basic_info.phone_number,
            gst_no: customer.billing_info.GST_no,
            address: customer.basic_info.shipping_address.address_line1,
            customerNo:customer.customer_no
          })
        );
        setCustomerOptions(customerOptions);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const checkForErrors = (): boolean => {
    for (const rowIndex in tableFieldErrors) {
      for (const field in tableFieldErrors[rowIndex]) {
        if (tableFieldErrors[rowIndex][field]) {
          return true;
        }
      }
    }
    return false;
  };

  const areAllRowsComplete = (rows: RowData[]): boolean => {
    return rows.every((row) => {
      return Object.values(row).every((value) => value !== "");
    });
  };

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    const hasTableErrors = checkForErrors();
    setConfirmDisable(hasErrors);
    setConfirmDisable(hasTableErrors);
  }, [fieldErrors, tableFieldErrors]);

  useEffect(() => {
    const isButtonDisabled = areAllFieldsHasValues(quotesData);

    if (isButtonDisabled) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [quotesData]);

  useEffect(() => {
    const isAllRows = areAllRowsComplete(rows);
    if (isAllRows) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [rows]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const mapRowsToProductOrService = (rows: RowData[]): ProductOrService[] => {
    return rows.map((row) => ({
      name: row["Product Details"] || null,
      tax: row["Tax"] || null,
      amount: row["Amount"] || null,
      rate: row["Rate"] || null,
      quantity: row["Quantity"] || null,
    }));
  };
  useEffect(() => {
    if (editQuote) {
      setQuotesData({
        "Customer Name": editQuote.name,
        "Customer Id": editQuote.customerId,
        "Product Plan": editQuote.productplan,
        "Phone Number": editQuote.phoneNumber,
        Email: editQuote.email,
        Address: editQuote.address,
        Amount: editQuote.total,
        "Customer Notes": editQuote.description,
        "Expiry Date": editQuote.duedate,
        "Quotation Date": editQuote.quotationdate,
        "GST Number": editQuote.gstNumber || "",
        "GST Type": editQuote.gstType,
        "Quotation Notes": editQuote.quotationnotes,
        "HSN Number": editQuote.hsnCode,
      });
      const customer = customerOptions.find(
        (customer) => customer.name === editQuote.name
      );
      if (customer) {
        setQuotesData((prevQuotesData) => ({
          ...prevQuotesData,
          "Customer ID": customer.id,
        }));
      }
      setRows(mapProductOrServiceToRows(editQuote.productOrService));
    }
    setStatus(editQuote?.status || "");
  }, [editQuote]);

  const mapProductOrServiceToRows = (
    productOrService: ProductOrService[]
  ): RowData[] => {
    return productOrService.map((service) => ({
      "Product Details": service.name || "",
      Quantity: service.quantity || "",
      Rate: service.rate || "",
      Tax: service.tax || "",
      Amount: service.amount || "",
    }));
  };

  const customCloseIcon = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      style={{ justifyContent: "end", width: "200px" }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleConvertToInvoice = async () => {
    const productOrService = mapRowsToProductOrService(rows);
    const userId = localStorage.getItem("userId");
    const query = ADD_INVOICE_MUTATION;
    const variables = {
      user_id: userId,
      invoicedate: quotesData["Quotation Date"],
      duedate: quotesData["Expiry Date"],
      expiry_date: quotesData["Expiry Date"],
      productOrService: productOrService,
      price: quotesData["Amount"],
      paid_amount: "0",
      status: "Unpaid",
      gstType: quotesData["GST Type"],
      customer_name: quotesData["Customer Name"],
      customer_id: quotesData["Customer Id"],
      phone_no: quotesData["Phone Number"],
      email: quotesData["Email"],
      description: quotesData["Customer Notes"],
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const { addInvoice } = response.data.data;
      if (addInvoice && addInvoice.id) {
        setSuccMessage(TEXT_MESSAGES.INVOICE_SUCCESS);
      } else {
        setSuccMessage(TEXT_MESSAGES.Invoice_FAILURE);
      }
      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/invoice");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async () => {
    const userId = localStorage.getItem("userId");
    const productOrService = mapRowsToProductOrService(rows);
    const query = `
    mutation AddQuotation($user_id: ID!
      $name: String!
      $quotationdate: String!
      $productOrService: [ProductOrServiceInput!]!
      $productplan: String!
      $description: String!
      $quotationnotes: String
      $subtotal: String!
      $total: String!
      $customerName: String!
      $hsnCode: String!
      $customerId:String!
      $phoneNumber:String!
      $gstNumber:String!
      $gstType:String!
      $email: String!
      $address: String!
      $duedate: String!
      $price: String!
      $status: String! ){
      addQuotation(user_id: $user_id
        name: $name
        quotationdate: $quotationdate
        productOrService: $productOrService
        productplan: $productplan
        description: $description
        quotationnotes: $quotationnotes
        subtotal: $subtotal
        gstNumber:$gstNumber
        gstType:$gstType
        total: $total
        customerName: $customerName
        hsnCode: $hsnCode
        customerId: $customerId
        phoneNumber:$phoneNumber
        email:$email
        address:$address
        duedate: $duedate
        price: $price
        status: $status){
          id
          serial_no
          user_id
          name
          quotationid
          quotationdate
          productOrService {
        name
        tax
        amount
        rate
        quantity
      }
          duedate
          productplan
          description
          quotationnotes
          subtotal
          total
          gstNumber
          hsnCode
          gstType
          customerName
          customerId
          phoneNumber
          email
          address
          price
          status
      }
    }
    `;

    const variables = {
      user_id: userId,
      name: quotesData["Customer Name"],
      quotationdate: quotesData["Quotation Date"],
      productOrService: productOrService,
      productplan: quotesData["Product Plan"],
      description: quotesData["Customer Notes"],
      quotationnotes: quotesData["Quotation Notes"],
      subtotal: typeof quotesData["Amount"] === 'string' ? quotesData["Amount"] : String(quotesData["Amount"]),
      total: typeof quotesData["Amount"] === 'string' ? quotesData["Amount"] : String(quotesData["Amount"]),
      gstNumber: quotesData["GST Number"],
      gstType: quotesData["GST Type"],
      hsnCode: quotesData["HSN Number"],
      customerName: quotesData["Customer Name"],
      customerId: quotesData["Customer Id"],
      email: quotesData["Email"],
      phoneNumber: quotesData["Phone Number"],
      address: quotesData["Address"],
      duedate: quotesData["Expiry Date"],
      price: typeof quotesData["Amount"] === 'string' ? quotesData["Amount"] : String(quotesData["Amount"]),
      status: status,
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const { addQuotation } = response.data.data;

      if (addQuotation && addQuotation.id) {
        setSuccMessage(TEXT_MESSAGES.QUOTATION_SUCCESS);
      } else {
        setSuccMessage(TEXT_MESSAGES.Quotation_FAILURE);
      }

      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/quotes");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = async () => {
    const userId = localStorage.getItem("userId");
    const productOrService = mapRowsToProductOrService(rows);
    const query = `
    mutation EditQuotation(
      $_id: ID!
      $user_id: ID!
      $name: String!
      $quotationdate: String!
      $productOrService: [ProductOrServiceInput!]!
      $productplan: String!
      $description: String!
      $quotationnotes: String
      $subtotal: String!
      $gstNumber:String!
      $gstType:String!
      $total: String!
      $customerName: String!
      $hsnCode: String!
      $customerId:String!
      $phoneNumber:String!
      $email: String!
      $address: String!
      $duedate: String!
      $price: String!
      $status: String! ){
        editQuotation(
        _id: $_id
        user_id: $user_id
        name: $name
        quotationdate: $quotationdate
        productOrService: $productOrService
        productplan: $productplan
        description: $description
        quotationnotes: $quotationnotes
        subtotal: $subtotal
        total: $total
        gstNumber:$gstNumber
        gstType:$gstType
        customerName: $customerName
        hsnCode : $hsnCode
        customerId: $customerId
        phoneNumber:$phoneNumber
        email:$email
        address:$address
        duedate: $duedate
        price: $price
        status: $status){
          id
          serial_no
          user_id
          name
          quotationid
          quotationdate
          productOrService {
        name
        tax
        amount
        rate
        quantity
      }
        productplan
          duedate
          description
          quotationnotes
          subtotal
          total
          customerName
          customerId
          phoneNumber
          gstNumber
          hsnCode
          gstType
          email
          address
          price
          status
      }
    }
    `;

    const variables = {
      _id: editQuote.id,
      user_id: userId,
      name: quotesData["Customer Name"],
      quotationdate: quotesData["Quotation Date"],
      productOrService: productOrService,
      productplan: quotesData["Product Plan"],
      description: quotesData["Customer Notes"],
      quotationnotes: quotesData["Quotation Notes"],
      subtotal: typeof quotesData["Amount"] === 'string' ? quotesData["Amount"] : String(quotesData["Amount"]),
      total: typeof quotesData["Amount"] === 'string' ? quotesData["Amount"] : String(quotesData["Amount"]),
      gstNumber: quotesData["GST Number"],
      gstType: quotesData["GST Type"],
      hsnCode: quotesData["HSN Number"],
      customerName: quotesData["Customer Name"],
      customerId: quotesData["Customer Id"],
      email: quotesData["Email"],
      phoneNumber: quotesData["Phone Number"],
      address: quotesData["Address"],
      duedate: quotesData["Expiry Date"],
      price: typeof quotesData["Amount"] === 'string' ? quotesData["Amount"] : String(quotesData["Amount"]),
      status: status,
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const { editQuotation } = response.data.data;

      if (editQuotation && editQuotation.id) {
        setSuccMessage(TEXT_MESSAGES.QUOTATION_UPDATE);
      } else {
        setSuccMessage(TEXT_MESSAGES.Quotation_UPDATE_FAILURE);
      }

      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/quotes");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setQuotesData({
      "Expiry Date": "",
      "Quotation Date": "",
      "Product Plan": "",
      "Customer Notes": "",
      Address: "",
      Amount: "",
      "Customer Name": "",
      Email: "",
      "GST Number": "",
      "Phone Number": "",
      "Quotation Notes": "",
      "HSN Number": "",
    });
    navigate("/quotes");
  };

  return (
    <Box
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "1%",
        }}
      >
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Roboto",
            color: "#181D8C",
            fontWeight: 600,
            marginLeft: "1%",
            lineHeight: "29px",
          }}
        >
          {editQuote
            ? TEXT_MESSAGES.QUOTE_DETAIL
            : TEXT_MESSAGES.CREATE_QUOTATION}
        </Typography>
        {editQuote && (
          <Box
            sx={{
              backgroundColor: "#EAEBFF",
              marginRight: "3%",
              padding: "5px",
              width: "280px",
              borderRadius: "8px",
            }}
          >
            {" "}
            <Typography
              style={{
                fontSize: "18px",
                fontFamily: "Roboto",
                color: "#181D8C",
                fontWeight: 600,
                marginLeft: "2%",
              }}
            >
              {TEXT_MESSAGES.QUOTE_NUMBER}:{editQuote.quotationid}
            </Typography>
          </Box>
        )}
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
          border: "0.5px solid #B7BAFF",
          padding: "5px",
          width: "95%",
          marginLeft: "1%",
          borderRadius: "4px",
          height: "70vh",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            margin: "5px",
            padding: "5px",
            marginTop: "0px",
            marginLeft: "-5px",
            marginRight: "0%",
            width: "95%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1%",
              marginLeft: "1%",
            }}
          >
  { editQuote?.status === "Accepted"  &&        <Button
              onClick={handleConvertToInvoice}
              sx={{
                color: "white",
                border: "1px solid",
                backgroundColor: "#181D8C",
                height: "35px",
                borderRadius: "5px",
                marginRight: "1%",
                textTransform: "none",
                "&:hover": { backgroundColor: "#181D8C" },
                "&:active": { backgroundColor: "#181D8C" },
              }}
            >
              {TEXT_MESSAGES.CONVERT_TXT}
            </Button>}
          </Box>
          <QuoteTable
            QuotesData={quotesData}
            setQuotesData={setQuotesData}
            status={status}
            setStatus={setStatus}
            QuotationDate={quotationDate}
            setQuotationDate={setQuotationDate}
            ExpiryDate={expiryDate}
            setExpiryDate={setExpiryDate}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            customerOptions={customerOptions}
            setCustomerOptions={setCustomerOptions}
            rows={rows}
            setRows={setRows}
            tableFieldErrors={tableFieldErrors}
            setTableFieldErrors={setTableFieldErrors}
          />
        </Box>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignSelf: "flex-end",
          marginTop: "10px",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "0.5px",
            width: "100%",
            backgroundColor: "#CBC2E9",
          }}
        ></div>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Button
            onClick={handleCancel}
            style={{
              color: "grey",
              backgroundColor: "white",
              border: "1px solid #1C1B1F",
              width: "125px",
              height: "36px",
              borderRadius: "5px",
              marginLeft: "2%",
            }}
          >
            {TEXT_MESSAGES.CANCEL}
          </Button>
          <Button
            onClick={editQuote ? handleEdit : handleSave}
            disabled={buttonDisable || confirmDisable}
            sx={{
              color: "white",
              border: "1px solid",
              backgroundColor:
                buttonDisable || confirmDisable ? "lightgray" : "#181D8C",
              width: "125px",
              height: "36px",
              borderRadius: "5px",
              marginRight: "2%",
              "&:hover": { backgroundColor: "#181D8C" },
              "&:active": { backgroundColor: "#181D8C" },
            }}
          >
            {editQuote ? TEXT_MESSAGES.UPDATE_TXT : TEXT_MESSAGES.SAVE}
          </Button>
        </Box>
      </div>
      <SnackBar
        open={modalOpen}
        onClose={handleClose}
        message={succMessage}
        vertical="top"
        horizontal="center"
        customAction={customCloseIcon}
      />
    </Box>
  );
};
export default NewQuotation;

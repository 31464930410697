import React, { useState, useEffect } from "react";
import SearchComponent from "../Components/PheonixSearch";
import { api } from "../api";
import axios from "axios";
import Order from "../interfaces/Order";
import PheonixPaper from "../Components/PheonixPaper";
import { useTheme } from "@mui/material/styles";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import PheonixChip from "../Components/PheonixChip";
import PheonixButton from "../Components/PheonixButton";
import Popover from "@mui/material/Popover";
import { TEXT_MESSAGES } from "../const";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PheonixCustomerModal from "../Components/PheonixCustomerModal";
import { GET_CUSTOMER_QUERY } from "../graphql/query";
import {
  ADD_CUSTOMER_MUTATION,
  EDIT_CUSTOMER_MUTATION,
  DELETE_CUSTOMER,
} from "../graphql/mutation";
import more from "../assets/Vector.png";
import CustomerProps from "../interfaces/customerPRops";
import PheonixSnackBar from "../Components/PheonixSnackBar";

interface CustomerData {
  "Company Name": string;
  "Contact Number": string;
  "Contact Email ID": string;
  "Customer Full Name": string;
  "GST Number": string;
  Address: string;
}
interface Field {
  label: keyof CustomerData;
  type: string;
}
const initialCustomerData: CustomerData = {
  "Company Name": "",
  "Contact Number": "",
  "Contact Email ID": "",
  "Customer Full Name": "",
  "GST Number": "",
  Address: "",
};
const Customer: React.FC = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [ordersData, setOrdersData] = useState<CustomerProps[]>([]);
  const [switchSelected, setSwitchSelected] = useState(false);
  const [filteredOrdersData, setFilteredOrdersData] = useState<CustomerProps[]>(
    []
  );
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [editMode, setIsEditMode] = useState(false);
  const [containerEl, setContainerEl] = useState(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [customerData, setCustomerData] =
    useState<CustomerData>(initialCustomerData);
  const [customerList, setCustomerList] = useState<CustomerProps[]>([]);
  const [editid, setEditId] = useState("");
  const [editCustomerId, setEditCustomerId] = useState("");
  const fetchCustomerData = async () => {
    const user_id = localStorage.getItem("userId");
    try {
      const response = await axios.post(api.baseUrl, {
        query: GET_CUSTOMER_QUERY,
        variables: {
          page: paginationModel.page,
          perPage: paginationModel.pageSize,
        },
      });
      const customers = response.data.data.getAddedUsers.users;
      const flattenData = (data: any) => {
        return data.map((item: any) => ({
          id: item._id,
          firstname: item.basic_info.firstname,
          company_name: item.basic_info.company_name,
          gst_no: item.billing_info.GST_no,
          email: item.basic_info.email,
          phone_number: item.basic_info.phone_number,
          address:item.basic_info.shipping_address.address_line1,
          ...item,
        }));
      };

      const responseDataWithIndex = flattenData(customers);

      setCustomerList(responseDataWithIndex);
      setTotalPages(response.data.data.getAddedUsers.totalCount);
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchCustomerData();
    setIsEditMode(false);
  }, [paginationModel.page, paginationModel.pageSize]);

  const [succMessage, setSuccMessage] = useState("");
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleViewClick = (id: string) => {
    const editCustomer = customerList.find((obj) => obj.id === id);
    setContainerEl(null);
    if (editCustomer) {
      setEditCustomerId(editCustomer.id);
      setCustomerData({
        "Company Name": editCustomer.company_name,
        "Contact Number": editCustomer.phone_number,
        "Contact Email ID": editCustomer.email,
        "Customer Full Name": editCustomer.firstname,
        "GST Number": editCustomer.gst_no,
        Address: editCustomer.address,
      });
      setOpen(true);
      setIsEditMode(true);
    }
  };

  const open1 = Boolean(containerEl);
  const id1 = open1 ? "simple-popover" : undefined;
  const handleClick = (event: any, id: string) => {
    setEditId(id);
    setAnchorEl(event.currentTarget);
    setContainerEl(event.currentTarget);
  };

  const handleDeleteClick = async (id: string) => {
    setAnchorEl(null);
    setContainerEl(null);
    const query = DELETE_CUSTOMER;
    const variables = {
      id: id,
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const { success,message } = response.data.data.deleteUserById;
      if (success) {
        setSuccMessage(TEXT_MESSAGES.CUSTOMER_DELETE_SUCCESS);
      } else {
        setSuccMessage(TEXT_MESSAGES.CUSTOMER_DELETE_FAIL);
      }
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
        fetchCustomerData();
      }, 3000);
    } catch (error) {
      console.error(error);
    }
    
  };
  const handleClosePop = () => {
    setAnchorEl(null);
    setContainerEl(null);
  };
  const handleConfirm = async () => {
    const userId = localStorage.getItem("userId");
    if (editMode) {
      const query = EDIT_CUSTOMER_MUTATION;
      const variables = {
        _id: editCustomerId,
        user_id: userId,
        company_name: customerData["Company Name"],
        phone_number: customerData["Contact Number"],
        email: customerData["Contact Email ID"],
        firstname: customerData["Customer Full Name"],
        GST_no: customerData["GST Number"],
        address_line1: customerData["Address"],
        mail_status: switchSelected,
      };
      try {
        const response = await axios.post(api.baseUrl, {
          query,
          variables,
        });
        const { success,message } = response.data.data.editUser;
        setOpen(false);
        if (success) {
          setSuccMessage(TEXT_MESSAGES.CUSTOMER_EDIT_SUCCESS);
        } else {
          setSuccMessage(message);
        }
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          fetchCustomerData();
          setCustomerData({
            "Company Name": "",
            "Contact Number": "",
            "Contact Email ID": "",
            "Customer Full Name": "",
            "GST Number": "",
            Address: "",
          });
          setIsEditMode(false);
        }, 3000);
      } catch (error) {
        console.error(error);
      }
    } else {
      const query = ADD_CUSTOMER_MUTATION;
      const variables = {
        user_id: userId,
        company_name: customerData["Company Name"],
        phone_number: customerData["Contact Number"],
        email: customerData["Contact Email ID"],
        firstname: customerData["Customer Full Name"],
        GST_no: customerData["GST Number"],
        address_line1: customerData["Address"],
        mail_status: switchSelected,
      };

      try {
        const response = await axios.post(api.baseUrl, {
          query,
          variables,
        });
        const { success, message } = response.data.data.addUser;
        setOpen(false);
        if (success) {
          if (typeof message === "string" && message.includes("Successfully")) {
            setSuccMessage(TEXT_MESSAGES.CUSTOMER_SUCCESS);
          } else {
            setSuccMessage(TEXT_MESSAGES.Customer_FAILURE);
          }
        }else{
          setSuccMessage(message)
        }

        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          setCustomerData({
            "Company Name": "",
            "Contact Number": "",
            "Contact Email ID": "",
            "Customer Full Name": "",
            "GST Number": "",
            Address: "",
          });
          fetchCustomerData();
        }, 3000);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "Active":
        return "success";
      case "Inactive":
        return "error";

      default:
        return "default";
    }
  };
  const fields: Field[] = [
    { label: "Company Name", type: "text" },
    { label: "Contact Number", type: "text" },
    { label: "Contact Email ID", type: "number" },
    { label: "Customer Full Name", type: "text" },
    { label: "GST Number", type: "text" },
    { label: "Address", type: "text" },
  ];
  const columns = [
    { field: "firstname", headerName: "Name", width: 170 },
    { field: "company_name", headerName: "Company Name", width: 200 },
    { field: "gst_no", headerName: "GST Number", width: 170 },
    { field: "email", headerName: "Contact Email ID", width: 200 },
    { field: "phone_number", headerName: "Contact Number", width: 200 },
    {
      field: "More",
      headerName: "More",
      renderCell: (params: any) => (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "50px",
            cursor: "pointer",
            marginLeft:'-3px'
          }}
          onClick={(e) => {
            handleClick(e, params.row.id);
          }}
        >
          <img
            src={more}
            alt="Your Alt Text"
            style={{ width: "5px", height: "20px", cursor: "pointer" }}
            onClick={(e) => {
              handleClick(e, params.row.id);
            }}
          />
        </div>
      ),
      width: 100,
    },
  ];
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const handleSearch = (searchQuery: string) => {
    const filteredData = customerList.filter((quotation) =>
      Object.values(quotation).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredOrdersData(filteredData);
  };
  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setCustomerData({
      "Company Name": "",
      "Contact Number": "",
      "Contact Email ID": "",
      "Customer Full Name": "",
      "GST Number": "",
      Address: "",
    });
    setSwitchSelected(false)
    setIsEditMode(false);
    setOpen(false);
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "84vh",
        padding: "20px",
        maxWidth: isSidebarOpen ? "calc(100% - 70px)" : "calc(100% - 80px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontFamily: "Roboto",
            color: "#181D8C",
            fontWeight: 600,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          {TEXT_MESSAGES.CUSTOMER}
        </Typography>
        <div style={{ marginRight: "-5px" }}>
          <PheonixButton
            style={{
              marginRight: "10px",
              color: "white",
              border: "1px solid #1C1B1F",
              backgroundColor: "#181D8C",
            }}
            onClick={handleOpen}
            label={TEXT_MESSAGES.NEW_CUSTOMER}
          ></PheonixButton>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "5px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={
            filteredOrdersData.length > 0 ? filteredOrdersData : customerList
          }
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          
        />
      </div>
      <PheonixCustomerModal
        switchSelected={switchSelected}
        setSwitchSelected={setSwitchSelected}
        open={open}
        edit={editMode}
        fields={fields}
        onClose={handleClose}
        formData={customerData}
        setFormData={setCustomerData}
        handleConfirm={handleConfirm}
      />
      <PheonixSnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={succMessage}
        vertical="top"
        horizontal="center"
      />
      <Popover
        id={id1}
        open={open1}
        anchorEl={containerEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box>
          <Typography
            sx={{ p: 2, cursor: "pointer" }}
            onClick={() => handleViewClick(editid)}
          >
            {TEXT_MESSAGES.EDIT_TXT}
          </Typography>
          <Typography
            sx={{ p: 2, cursor: "pointer" }}
            onClick={() => handleDeleteClick(editid)}
          >
            {TEXT_MESSAGES.DELETE_TXT}
          </Typography>
        </Box>
      </Popover>
    </div>
  );
};
export default Customer;

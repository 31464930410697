import React, { useState, useEffect } from "react";
import SearchComponent from "../Components/PheonixSearch";
import { api } from "../api";
import axios from "axios";
import Order from "../interfaces/Order";
import PheonixPaper from "../Components/PheonixPaper";
import { useTheme } from "@mui/material/styles";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { Typography, Box } from "@mui/material";
import { TEXT_MESSAGES } from "../const";
import { EDIT_ORDER_MUTATION } from "../graphql/mutation";
import PheonixButton from "../Components/PheonixButton";
import OrderProps from "../interfaces/OrderProps";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { pdfdata } from "../const";
import logo from "../assets/logo.jpg";
import OrderModal from "../Components/OrderModal";
import PheonixSnackBar from "../Components/PheonixSnackBar";

interface OrderData {
  "Company Name": string;
  "Customer Name": string;
  "Contact Number": string;
  "Contact Email ID": string;
  "Product/Service": string;
  Plan: string;
  "Order Specific Details": string;
  "Customer Id": string;
  "Renewal Date"?: string;
  "Actual Amount": string;
  "Proposed Amount": string;
}

interface Field {
  label: keyof OrderData;
  type: string;
}

const initialCustomerData: OrderData = {
  "Company Name": "",
  "Customer Name": "",
  "Contact Number": "",
  "Contact Email ID": "",
  "Product/Service": "",
  Plan: "",
  "Order Specific Details": "",
  "Customer Id": "",
  "Renewal Date": "",
  "Actual Amount": "",
  "Proposed Amount": "",
};

const Orders: React.FC = () => {
  const theme = useTheme();
  const [ordersData, setOrdersData] = useState<Order[]>([]);
  const [filteredOrdersData, setFilteredOrdersData] = useState<Order[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [error, setError] = useState("");
  const [editid, setEditId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [succMessage, setSuccMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [editMode, setIsEditMode] = useState(false);
  const [customerData, setCustomerData] =
    useState<OrderData>(initialCustomerData);
  const [apiresponse, setApiresponse] = useState([]);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleEdit = (id: string) => {
    const editOrder = ordersData.find((obj) => obj.id === id);
    setEditId(id);
    if (editOrder) {
      setCustomerData({
        "Company Name": editOrder.company_name,
        "Customer Name": editOrder.customer_name,
        "Contact Number": editOrder.contact_no,
        "Contact Email ID": editOrder.contact_email,
        "Product/Service": editOrder.productOrService[0],
        Plan: editOrder.plan,
        "Order Specific Details": editOrder.order_details,
        "Customer Id": editOrder.customerId,
        "Renewal Date": editOrder.renewal,
        "Actual Amount": editOrder.actual_amount,
        "Proposed Amount": editOrder.proposed_amount,
      });
      setSelectedProducts(editOrder.productOrService);
      setOpen(true);
      setIsEditMode(true);
    }
  };
  const imageUrl = logo;
  const handleDownload = (id: string) => {
    const rowData = ordersData.find((obj) => obj.id === id);
    if (rowData) {
      const doc = new jsPDF() as any;
      doc.addImage(imageUrl, "JPEG", 15, 10, 50, 35);
      doc.setFontSize(40);
      doc.setFont("helvetica", "bold");
      doc.text("Order", 154, 20);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      const pageWidth = doc.internal.pageSize.getWidth();
      const marginRight = 15;
      const labelMarginLeft = 4; 
      
      const rightAlignTextWithColors = (
        label:any, value:any, labelColor:any, valueColor:any, yPosition:any, applyLabelMarginLeft:any
      ) => {
        const labelWidth =
          (doc.getStringUnitWidth(label) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const valueWidth =
          (doc.getStringUnitWidth(value) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const totalWidth = labelWidth + valueWidth;
      
        const xLabel = pageWidth - totalWidth - marginRight - (applyLabelMarginLeft ? labelMarginLeft : 0);
        const xValue = xLabel + labelWidth + (applyLabelMarginLeft ? labelMarginLeft : 0); 
      
        doc.setTextColor(labelColor);
        doc.text(label, xLabel, yPosition);
      
        doc.setTextColor(valueColor);
        doc.text(value, xValue, yPosition);
      };
      
      const texts = [
        {
          label: "Order ID : ",
          value: rowData.order_no,
          labelColor: "#808080",
          valueColor: "#000000",
          applyLabelMarginLeft: true,
        },
        {
          label: "Invoice Date : ",
          value: rowData.orderdate,
          labelColor: "#808080",
          valueColor: "#000000",
          applyLabelMarginLeft: false, 
        },
        {
          label: "Valid Till : ",
          value: rowData.renewal,
          labelColor: "#808080",
          valueColor: "#000000",
          applyLabelMarginLeft: false, 
        },
      ];
      
      const yPositions = [28, 35, 42];
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      
      texts.forEach((text, index) => {
        rightAlignTextWithColors(
          text.label,
          text.value,
          text.labelColor,
          text.valueColor,
          yPositions[index],
          text.applyLabelMarginLeft
        );
      });


      doc.setTextColor("#808080");
      doc.text("From ", 15, 60);
      doc.setTextColor("#000000");
      const pheonixAddress = pdfdata.PHEONIX_ADDRESS;
      doc.text(pheonixAddress, 12, 65);
      
      const text1 = "To ";
      const text2 = rowData.customer_name;
      const text3 = rowData.contact_no;

      const textWidth1 =
        (doc.getStringUnitWidth(text1) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const textWidth2 =
        (doc.getStringUnitWidth(text2) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const textWidth3 =
        (doc.getStringUnitWidth(text3) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;

      const x1 = pageWidth - textWidth1 - marginRight;
      const x2 = pageWidth - textWidth2 - marginRight;
      const x3 = pageWidth - textWidth3 - marginRight;
      doc.setTextColor("#808080");
      doc.text(text1, x1, 60);
      doc.setTextColor("#000000");
      doc.text(text2, x2, 70);
      doc.text(text3, x3, 75);
      const productNames = rowData.productOrService.map(
        (service: any) => service
      );
      const bodyData = productNames.map((name) => [
        name,
        rowData.order_details,
        rowData.proposed_amount,
      ]);
      let startY = 110;
      var customStyles = {
        fillColor: [255, 255, 255],
        lineHeight: 50,
        cellPadding: 2,
        textColor: [0, 0, 0],
        fontSize: 12,
      };
      doc.autoTable({
        startY,
        head: [["Product/Service", "Description", "Price"]],
        body: bodyData,
        styles: customStyles,
        didParseCell: function (table: any) {
          if (table.section === "head") {
            table.cell.styles.textColor = "#000000";
            table.cell.styles.fontSize = 12;
          }
        },
      });
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      const text = `Total : ${rowData.proposed_amount}`;
      doc.text(text, 170, 160);

      doc.save("order.pdf");
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    fetchOrderData();
  }, [currentPage]);

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const columns = [
    { field: "orderdate", headerName: "Date", width: 150 },
    { field: "order_no", headerName: "Order Number", width: 175 },
    {
      field: "productOrService",
      headerName: "Product",
      width: 175,
      renderCell: (params:any) => (
        <div >
          {params.value.map((item:any, index:any) => (
            <div key={index}>
              {item}
            </div>
          ))}
        </div>
      ),
    },
    { field: "renewal", headerName: "Due Date", width: 150 },
    {
      field: "proposed_amount",
      headerName: "Amount",
      width: 175,
      renderCell: (params: any) => {
        return params.value != null ? `₹ ${params.value}` : "NA";
      },
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params: { row: any }) => (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "50px",
            flexDirection: "row",
          }}
        >
          {loggedInUser === "1" ? (
            <div>
              <PreviewOutlinedIcon
                style={{ color: "#2196F3", cursor: "pointer" }}
                onClick={() => {
                  handleEdit(params.row.id);
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
          <div>
            <SimCardDownloadOutlinedIcon
              style={{ color: "#2196F3", cursor: "pointer" }}
              onClick={() => {
                handleDownload(params.row.id);
              }}
            />
          </div>
        </div>
      ),
      width: 100,
    },
  ];

  const handleSearch = (searchQuery: string) => {
    const filteredData = ordersData.filter((order) =>
      Object.values(order).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredOrdersData(filteredData);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCustomerData(initialCustomerData);
    setSelectedProducts([]);
    setIsEditMode(false);
    setOpen(false);
  };

  const handleConfirm = async () => {
    const {
      "Company Name": company_name,
      "Customer Name": customer_name,
      "Contact Number": contact_no,
      "Contact Email ID": contact_email,
      Plan: plan,
      "Order Specific Details": order_details,
      "Customer Id": customerId,
      "Renewal Date": renewal,
      "Actual Amount": actual_amount,
      "Proposed Amount": proposed_amount,
    } = customerData;

    const user_id = localStorage.getItem("userId");
    if (editMode) {
      try {
        const response = await axios.post(api.baseUrl, {
          query: EDIT_ORDER_MUTATION,
          variables: {
            _id: editid,
            user_id,
            company_name,
            customer_name,
            customerId,
            contact_no,
            contact_email,
            productOrService: selectedProducts,
            plan,
            order_details,
            renewal,
            actual_amount,
            proposed_amount,
          },
        });
        const { editOrder } = response.data.data;
        if (editOrder && editOrder.id) {
          setSuccMessage(TEXT_MESSAGES.ORDER_UPDATE_SUCCESS);
        } else {
          setSuccMessage(TEXT_MESSAGES.ORDER_UPDATE_FAILED);
        }
        setOpen(false);
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          fetchOrderData();
          setCustomerData(initialCustomerData);
          setSelectedProducts([]);
          setIsEditMode(false);
        }, 3000);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const response = await axios.post(api.baseUrl, {
          query: `
        mutation AddOrder(
          $user_id: ID!, 
          $company_name: String!, 
          $customer_name: String!, 
          $customerId: String!,
          $contact_no: String!, 
          $contact_email: String!, 
          $productOrService: [String]!, 
          $plan: String!,
          $order_details: String!
          $renewal: String
          $actual_amount: String
          $proposed_amount: String
        ) {
          addOrder(
            user_id: $user_id, 
            company_name: $company_name, 
            customer_name: $customer_name, 
            customerId: $customerId,
            contact_no: $contact_no, 
            contact_email: $contact_email, 
            productOrService: $productOrService, 
            plan: $plan,
            order_details: $order_details,
            renewal:$renewal
            actual_amount: $actual_amount
            proposed_amount: $proposed_amount
          ) {
            id
            serial_no
            user_id
            name
            orderid
            status
            prodstatus
            price
            renewal
            orderdate
            order_no
            company_name
            customer_name
            customerId
            contact_no
            contact_email
            productOrService_id
            productOrService
            plan_id
            plan
            order_details
            actual_amount
            proposed_amount
          }
        }
      `,
          variables: {
            user_id,
            company_name,
            customer_name,
            customerId,
            contact_no,
            contact_email,
            productOrService: selectedProducts,
            plan,
            order_details,
            renewal,
            actual_amount,
            proposed_amount,
          },
        });
        const { addOrder } = response.data.data;
        if (addOrder && addOrder.id) {
          setSuccMessage(TEXT_MESSAGES.Order_SUCCESS);
        } else {
          setSuccMessage(TEXT_MESSAGES.ORDER_FAIL);
        }
        setOpen(false);
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          fetchOrderData();
          setCustomerData(initialCustomerData);
          setSelectedProducts([]);
          setIsEditMode(false);
        }, 3000);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const fetchOrderData = async () => {
    const user_id = localStorage.getItem("userId");
    const usertype = localStorage.getItem("usertype");
    if (usertype === "1") {
      try {
        const response = await axios.post(api.baseUrl, {
          query: `
          query GetOrderbyUser($userId: ID!, $page: Int!, $perPage: Int!) {
            getOrderbyUser(user_id: $userId, page: $page, perPage: $perPage) {
              totalPages
              totalCount
              orders {
                id
            serial_no
            user_id
            name
            orderid
            status
            prodstatus
            price
            renewal
            orderdate
            order_no
            company_name
            customer_name
            customerId
            contact_no
            contact_email
            invoice_no
            productOrService
            actual_amount
            proposed_amount
            plan
            order_details
              }
            }
          }
        `,
          variables: {
            userId: user_id,
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
          },
        });
        const responseDataWithIndex =
          response.data.data.getOrderbyUser.orders.map(
            (item: {}, index: number) => ({
              ...item,
              serial_no: (index + 1).toString(),
            })
          );

        setOrdersData(responseDataWithIndex);
        setTotalPages(response.data.data.getOrderbyUser.totalCount);
      } catch (error: any) {
        setError(error.message);
      }
    } else {
      try {
        const response = await axios.post(api.baseUrl, {
          query: `
          query GetOrderbyUserType($customerId: ID!, $page: Int!, $perPage: Int!) {
            getOrderbyUserType(customerId: $customerId, page: $page, perPage: $perPage) {
              totalPages
              totalCount
              orders {
                id
            serial_no
            user_id
            name
            orderid
            orderdate
            order_no
            company_name
            customer_name
            customerId
            contact_no
            contact_email
            invoice_no
            productOrService
            actual_amount
            proposed_amount
            renewal
            plan
            order_details
              }
            }
          }
        `,
          variables: {
            customerId: user_id,
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
          },
        });
        const responseDataWithIndex =
          response.data.data.getOrderbyUserType.orders.map(
            (item: {}, index: number) => ({
              ...item,
              serial_no: (index + 1).toString(),
            })
          );

        setOrdersData(responseDataWithIndex);
        setTotalPages(response.data.data.getOrderbyUserType.totalCount);
      } catch (error: any) {
        setError(error.message);
      }
    }
  };

  const fields: Field[] = [
    { label: "Customer Name", type: "text" },
    { label: "Company Name", type: "text" }, 
    { label: "Contact Number", type: "number" },
    { label: "Contact Email ID", type: "text" },
    { label: "Product/Service", type: "text" },
    { label: "Renewal Date", type: "text" },
    { label: "Plan", type: "text" },
    { label: "Order Specific Details", type: "text" },
    { label: "Actual Amount", type: "text" },
    { label: "Proposed Amount", type: "text" },
  ];

  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "84vh",
        padding: "20px",
        maxWidth: isSidebarOpen ? "calc(100% - 70px)" : "calc(100% - 80px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontFamily: "Roboto",
            color: "#181D8C",
            fontWeight: 600,
            textAlign: "center",
            lineHeight: "21px",
          }}
        >
          {TEXT_MESSAGES.ORDER}
        </Typography>
        <div style={{ marginRight: "-5px" }}>
          {loggedInUser === "1" && (
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                border: "1px solid #1C1B1F",
                backgroundColor: "#181D8C",
              }}
              onClick={handleOpen}
              label={TEXT_MESSAGES.NEW_ORDER}
            />
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={filteredOrdersData.length > 0 ? filteredOrdersData : ordersData}
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
        />
      </div>
      <OrderModal
        open={open}
        fields={fields}
        edit={editMode}
        onClose={handleClose}
        formData={customerData}
        setFormData={setCustomerData}
        handleConfirm={handleConfirm}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
      />
      <PheonixSnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={succMessage}
        vertical="top"
        horizontal="center"
      />
    </div>
  );
};
export default Orders;

import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/Analytics';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { useTheme } from '@mui/material/styles';
import logo from '../assets/sidebarlogo.png';
import text from '../assets/PHEONIX SOLUTIONS.png';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import SidebarItem from '../Components/PheonixSideBar';
import SidebarProps from '../interfaces/SidebarpRops';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Tooltip from '@mui/material/Tooltip';
import { TEXT_MESSAGES } from '../const';
const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggle }) => {
  const theme = useTheme();
  const [loggedInUser, setLoggedInUser] = useState<string>('');
  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const handleMenuClick = () => {
    onToggle();
  };
  const iconComponent = isOpen ? <ExpandCircleDownIcon style={{ width: "28px", height: "28px",transform:  "rotate(90deg)",marginBottom:'5px' }} /> : <MenuIcon />;
  let menuItems;
  if (loggedInUser === "1") {
    menuItems = [
      {
        icon: isOpen ? (<HomeOutlinedIcon  />) : (
          <Tooltip title="Home"><HomeOutlinedIcon  /></Tooltip>
        ), text: "Home", to: '/home'
      },
      {
        icon: isOpen ? (<ListAltOutlinedIcon  />) : (
          <Tooltip title="Customer"><ListAltOutlinedIcon  /></Tooltip>
        ), text: 'Customers', to: '/customer'
      },
     
      {
        icon: isOpen ? (<ListAltOutlinedIcon />) : (
          <Tooltip title="Orders"><ListAltOutlinedIcon  /></Tooltip>
        ), text: 'Orders', to: '/orders'
      },
      {
        icon: isOpen ? (<RequestQuoteOutlinedIcon  />) : (
          <Tooltip title="Quotation"><RequestQuoteOutlinedIcon  /></Tooltip>
        ), text: 'Quotations', to: '/quotes'
      },
      {
        icon: isOpen ? (<ReceiptOutlinedIcon  />) : (
          <Tooltip title="Invoice"><ReceiptOutlinedIcon /></Tooltip>
        ), text: 'Invoices', to: '/invoice'
      },
      {
        icon: isOpen ? (<AnalyticsOutlinedIcon  />) : (
          <Tooltip title="Report"><AnalyticsOutlinedIcon  /></Tooltip>
        ), text: 'Reports', to: '/report'
      }
    ];
  } else {
    menuItems = [
      {
        icon: isOpen ? (<HomeOutlinedIcon  />) : (
          <Tooltip title="Home"><HomeOutlinedIcon  /></Tooltip>
        ), text: "Home", to: '/dashboard'
      },
      {
        icon: isOpen ? (<ListAltOutlinedIcon  />) : (
          <Tooltip title="Orders"><ListAltOutlinedIcon  /></Tooltip>
        ), text: 'Orders', to: '/orders'
      },
      {
        icon: isOpen ? (<CategoryIcon  />) : (
          <Tooltip title="Product/Service"><CategoryIcon  /></Tooltip>
        ), text: 'Products/Services', to: '/products'
      },
      {
        icon: isOpen ? (<RequestQuoteOutlinedIcon  />) : (
          <Tooltip title="Quotation"><RequestQuoteOutlinedIcon  /></Tooltip>
        ), text: 'Quotations', to: '/quotes'
      },
      {
        icon: isOpen ? (<ReceiptOutlinedIcon  />) : (
          <Tooltip title="Invoice"><ReceiptOutlinedIcon  /></Tooltip>
        ), text: 'Invoices', to: '/invoice'
      },
      {
        icon: isOpen ? (<AnalyticsOutlinedIcon  />) : (
          <Tooltip title="Report"><AnalyticsOutlinedIcon /></Tooltip>
        ), text: 'Reports', to: '/report'
      }
    ];
  }
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isOpen ? 225 : 65,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
         background: 'linear-gradient(to bottom, #27489C, #A974DD)',
          fontSize: '16px',
          color: 'white',
          width: isOpen ? 225 : 65,
          boxSizing: 'border-box',
          transition: 'width 0.2s ease-in-out, visibility 0.2s linear',
          overflow: "hidden"
        },
      }}
      open={isOpen}
      onClose={onToggle}
      ModalProps={{
        keepMounted: true,
      }}
    >
        <div
          onClick={handleMenuClick}
          style={
            isOpen
              ? {
                  position: "absolute",
                  top: "27px",
                  right: "0px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }
              : {
                  position: "absolute",
                  top: "27px",
                  left: "25px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          {isOpen && <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
          <img src={logo} alt="Logo" style={{ height: '66px', width: "66px",marginBottom:'5px' }} />
          <img src={text} alt="Logo" style={{ height: '11px', width: "162px", }} />
          </div>}
          {iconComponent}
        </div>
      <List sx={{ top: '115px', left: '10px' }}>
        {menuItems.map((item, index) => (
          <SidebarItem key={index} icon={item.icon} text={item.text} to={item.to} />
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
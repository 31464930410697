import React, { useState, useEffect } from "react";
import SearchComponent from "../Components/PheonixSearch";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import { api } from "../api";
import axios from "axios";
import Invoicemock from "../interfaces/Invoice";
import PheonixPaper from "../Components/PheonixPaper";
import Popover from "@mui/material/Popover";
import PheonixButton from "../Components/PheonixButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../assets/logo.jpg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Typography, Box, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSignUpContext } from "../Contexts/SignUpContext";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import PheonixChip from "../Components/PheonixChip";
import PheonixModal from "../Components/PheonixModal";
import { TEXT_MESSAGES } from "../const";
import more from "../assets/Vector.png";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { pdfdata } from "../const";
import { GET_INVOICE_QUERY, GET_INVOICE_WITH_TYPE } from "../graphql/query";
const Invoicescreen: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useSignUpContext();
  const [open, setOpen] = React.useState(false);
  const [invoicessData, setInvoicesData] = useState<Invoicemock[]>([]);
  const [filteredInvoicesData, setFilteredInvoicesData] = useState<
    Invoicemock[]
  >([]);
  const [error, setError] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoicemock | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoice, setInvoice] = useState<Invoicemock | null>(null);
  const [serialNumberCounter, setSerialNumberCounter] = useState(1);
  const [editid, setEditId] = useState("");
  const [containerEl, setContainerEl] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const usertype = localStorage.getItem("usertype");

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const handleClick = (e: any, id: any) => {
    setEditId(id);
    setContainerEl(e.currentTarget);
  };

  const handleClosePop = () => {
    setContainerEl(null);
  };
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchInvoiceData();
  }, [currentPage, paginationModel.page, paginationModel.pageSize]);

  const fetchInvoiceData = async () => {
    const userId = localStorage.getItem("userId");
    const usertype = localStorage.getItem("usertype");
    if (usertype === "1") {
      try {
        const response = await axios.post(api.baseUrl, {
          query: GET_INVOICE_QUERY,
          variables: {
            userId: userId,
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
          },
        });
        const responseDataWithIndex =
          response.data.data.getInvoicebyUser.invoices.map(
            (item: {}, index: number) => ({
              ...item,
              serial_no: (index + 1).toString(),
            })
          );
        setInvoicesData(responseDataWithIndex);
        setTotalPages(response.data.data.getInvoicebyUser.totalCount);
      } catch (error: any) {
        setError(error.message);
      }
    } else {
      try {
        const response = await axios.post(api.baseUrl, {
          query: GET_INVOICE_WITH_TYPE,
          variables: {
            customer_id: userId,
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
          },
        });
        const responseDataWithIndex =
          response.data.data.getInvoicebyUserType.invoices.map(
            (item: {}, index: number) => ({
              ...item,
              serial_no: (index + 1).toString(),
            })
          );
        setInvoicesData(responseDataWithIndex);
        setTotalPages(response.data.data.getInvoicebyUserType.totalCount);
      } catch (error: any) {
        setError(error.message);
      }
    }
  };
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "paid":
        return "#4caf50";
      case "unpaid":
        return "#9e9e9e";
      case "partially paid":
        return "#ff5722";
      case "cancelled":
        return "#ff9800";
      case "overdue":
        return "#f44336";
      default:
        return "#000";
    }
  };
  const handleOpenModal = (invoice: Invoicemock) => {
    setSelectedInvoice(invoice);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    navigate("/NewInvoice");
  };

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const imageUrl = logo;
  const handleDownloadPDF = (rowData: Invoicemock) => {
    setContainerEl(null);
    const doc = new jsPDF() as any;
    doc.addImage(imageUrl, "JPEG", 15, 10, 50, 35);
    doc.setFontSize(40);
    doc.setFont("helvetica", "bold");
    doc.text("Invoice", 145, 20);
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const pageWidth = doc.internal.pageSize.getWidth();
    const marginRight = 15;
    const labelMarginLeft = 14; 
    
    const rightAlignTextWithColors = (
      label:any, value:any, labelColor:any, valueColor:any, yPosition:any, applyLabelMarginLeft:any
    ) => {
      const labelWidth =
        (doc.getStringUnitWidth(label) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const valueWidth =
        (doc.getStringUnitWidth(value) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const totalWidth = labelWidth + valueWidth;
    
      const xLabel = pageWidth - totalWidth - marginRight - (applyLabelMarginLeft ? labelMarginLeft : 0);
      const xValue = xLabel + labelWidth + (applyLabelMarginLeft ? labelMarginLeft : 0); 
    
      doc.setTextColor(labelColor);
      doc.text(label, xLabel, yPosition);
    
      doc.setTextColor(valueColor);
      doc.text(value, xValue, yPosition);
    };
    
    const texts = [
      {
        label: "Invoice ID : ",
        value: rowData.invoiceid,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: true, 
      },
      {
        label: "Invoice Date : ",
        value: rowData.invoicedate,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false, 
      },
      {
        label: "Valid Till : ",
        value: rowData.duedate,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false, 
      },
    ];
    
    const yPositions = [28, 35, 42];
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    
    texts.forEach((text, index) => {
      rightAlignTextWithColors(
        text.label,
        text.value,
        text.labelColor,
        text.valueColor,
        yPositions[index],
        text.applyLabelMarginLeft
      );
    });

    doc.setTextColor("#808080");
    doc.text("From ", 15, 60);
    doc.setTextColor("#000000");
    const pheonixAddress = pdfdata.PHEONIX_ADDRESS;
    doc.text(pheonixAddress, 12, 65);

    const text1 = "To ";

    const text2 = rowData.customer_name;
    const text3 = rowData.phone_no;
    const textWidth1 =
      (doc.getStringUnitWidth(text1) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textWidth2 =
      (doc.getStringUnitWidth(text2) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textWidth3 =
      (doc.getStringUnitWidth(text3) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    const x1 = pageWidth - textWidth1 - marginRight;
    const x2 = pageWidth - textWidth2 - marginRight;
    const x3 = pageWidth - textWidth3 - marginRight;
    doc.setTextColor("#808080");
    doc.text(text1, x1, 60);
    doc.setTextColor("#000000");
    doc.text(text2, x2, 70);
    doc.text(text3, x3, 75);
    const products = rowData.productOrService.map((service: any) => ({
      name: service.name,
      amount: service.amount,
    }));
    const bodyData = products.map((name) => [
      name.name,
      rowData.description,
      name.amount,
    ]);
    let startY = 110;
    var customStyles = {
      fillColor: [255, 255, 255],
      lineHeight: 50,
      cellPadding: 2,
      textColor: [0, 0, 0],
      fontSize: 12,
    };
    doc.autoTable({
      startY,
      head: [["Product/Service", "Description", "Price"]],
      body: bodyData,
      styles: customStyles,
      didParseCell: function (table: any) {
        if (table.section === "head") {
          table.cell.styles.textColor = "#000000";
          table.cell.styles.fontSize = 12;
        }
      },
    });
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    const text = `Total : ${rowData.price}`;
    doc.text(text, 170, 160);

    doc.save("invoice.pdf");
  };

  const [userType, setUserType] = useState<number | null>(null);
  useEffect(() => {
    const storedUserType = localStorage.getItem("usertype");
    if (storedUserType !== null) {
      const parsedUserType = parseInt(storedUserType, 10);
      setUserType(parsedUserType);
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedRow, setSelectedRow] = useState<Invoicemock | null>(null);

  const open1 = Boolean(containerEl);
  const id1 = open1 ? "simple-popover" : undefined;
  const handleClick1 = (event: any, id: number) => {
    const foundInvoice = invoicessData.find(
      (obj) => obj.id.toString() === id.toString()
    );
    const editInvoice: Invoicemock | null =
      foundInvoice !== undefined ? foundInvoice : null;
    setSelectedRow(editInvoice);
    setAnchorEl(event.currentTarget);
    setContainerEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    setContainerEl(null);
  };
  const handleViewClick = (row: Invoicemock) => {
    setContainerEl(null);
    navigate("/NewInvoice", { state: { row } });
  };
  const columns = [
    { field: "invoicedate", headerName: "Date", width: 150 },
    { field: "invoice_no", headerName: "Invoice Number", width: 175 },
    { field: "customer_name", headerName: "Customer Name", width: 175 },
    {
      field: "status",
      headerName: "Status",
      width: 175,
      renderCell: (params: { value: any }) => {
        const status = params.value;
        const iconColor = getStatusColor(status);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon style={{ color: iconColor, marginRight: "5px" }} />
            {status}
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Amount",
      width: 175,
      renderCell: (params: any) => {
        return params.value != null ? `₹ ${params.value}` : "NA";
      },
    },
    {
      field: "due_amount",
      headerName: "Amount Due",
      width: 150,
      renderCell: (params: any) => {
        return params.value !== null && !isNaN(params.value)
          ? `₹ ${params.value}`
          : "NA";
      },
    },
    ...(userType === 2
      ? [
          {
            field: "action",
            headerName: "Action",
            renderCell: (params: { row: Invoicemock }) => (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  width: "50px",
                  cursor: "pointer",
                }}
              >
                <SaveAltIcon
                  style={{ color: "#165D59" }}
                  onClick={() => handleDownloadPDF(params.row)}
                />
              </div>
            ),
            width: 100,
          },
        ]
      : [
          {
            field: "more",
            headerName: "More",
            renderCell: (params: { row: Invoicemock }) => (
              <div style={{ width: "5px", cursor: "pointer" }}>
                <MoreVertIcon
                  onClick={(e) => {
                    handleClick1(e, params.row.id);
                  }}
                />

                <Popover
                  id={id1}
                  open={open1}
                  anchorEl={containerEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {open1 && selectedRow && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        padding: "10px",
                        alignItems: "left",
                        textAlign: "left",
                      }}
                    >
                      <Button
                        style={{
                          textAlign: "left",
                          color: "#000000",
                          textTransform: "none",
                        }}
                        onClick={() => handleDownloadPDF(selectedRow)}
                      >
                        Download as pdf
                      </Button>
                      <Button
                        style={{
                          textAlign: "left",
                          color: "#000000",
                          textTransform: "none",
                        }}
                        onClick={() => handleViewClick(selectedRow)}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </Popover>
              </div>
            ),
            width: 175,
          },
        ]),
  ];

  const handleSearch = (searchQuery: string) => {
    const filteredData = invoicessData.filter((quotation) =>
      Object.values(quotation).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredInvoicesData(filteredData);
  };
  const handleDeleteClick = () => {
    setContainerEl(null);
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "84vh",
        padding: "20px",
        maxWidth: isSidebarOpen ? "calc(100% - 70px)" : "calc(100% - 80px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontFamily: "Roboto",
            color: "#181D8C",
            fontWeight: 600,
            textAlign: "center",
            lineHeight: "21px",
          }}
        >
          {TEXT_MESSAGES.INVOICE}
        </Typography>
        <div style={{ marginRight: "-10px" }}>
          {loggedInUser === "1" && (
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                border: "1px solid #1C1B1F",
                backgroundColor: "#181D8C",
              }}
              onClick={handleOpen}
              label={TEXT_MESSAGES.NEW_INVOICE}
            ></PheonixButton>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={
            filteredInvoicesData.length > 0
              ? filteredInvoicesData
              : invoicessData
          }
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};
export default Invoicescreen;

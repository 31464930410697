import React, { ChangeEvent, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PheonixTextField from '../Components/PheonixTextField';
import SubmitButton from '../Components/PheonixButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo from '../assets/logo.jpg';
import PheonixPaper from '../Components/PheonixPaper';
import PheonixBox from '../Components/PheonixBox';
import PheonixContainer from '../Components/PheonixContainer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container, styled } from '@mui/material';
import SnackBar from '../Components/PheonixSnackBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import axios from 'axios';
import { api } from '../api';
import { ERROR_MESSAGES, TEXT_MESSAGES } from '../const';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSignUpContext } from '../Contexts/SignUpContext';

const Item = styled('div')({
  marginBottom: '20px',
  padding: '8px',
  textAlign: 'center',
});
const ForgotPassword: React.FC = () => {
  const [otp, setotp] = useState('');
  const [newpassword, setnewpassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verificationMessage, setVerificationMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessage1, setErrorMessage1] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState('')
  const [buttonDisable, setButtonDisable] = useState(true);
  const { id } = useSignUpContext();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const cameFromLogin = sessionStorage.getItem('cameFromVerify');
    if (!cameFromLogin) {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    sessionStorage.removeItem('cameFromVerify');
  }, []);
  const handleConfirmpasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };
  const handlenewpasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setnewpassword(e.target.value);
  };
  const handleNewFocus = async () => {
    setErrorMessage('');
  };
  const handleNewBlur = () => {
    const minLength = 8;
    const passlength = newpassword.length >= minLength;
    if(newpassword.length===0){
      setErrorMessage(ERROR_MESSAGES.VALID_PASSWORD);
    }
   else if (!passlength) {
      setErrorMessage(ERROR_MESSAGES.PWD_LENGTH);
    } else {
      setErrorMessage('');
    }
  };
  const handleConfirmFocus = async () => {
    setErrorMessage1('');
  };
  const handleConfirmBlur = () => {
    if(confirmPassword.length===0){
      setErrorMessage1(ERROR_MESSAGES.VALID_PASSWORD);
    }
   else if (newpassword != confirmPassword) {
      setErrorMessage1(ERROR_MESSAGES.PWD_NOTMATCH);
    }
  };
  useEffect(() => {
    const passwordValid = isValidPassword(newpassword, confirmPassword);
    if (newpassword && passwordValid && !errorMessage && !errorMessage1) {
      setButtonDisable(false)
    } else {
      setButtonDisable(true)
    }
  }, [newpassword, confirmPassword]);
  const isValidPassword = (newpassword: string, confirmPassword: string): boolean => {
    if (newpassword == confirmPassword) {
      return true;
    } else {
      return false;
    }
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
     };
  const handleTogglePassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handlechangepassword = async () => {
    const userId = localStorage.getItem("userId");
    const query = ` mutation ChangePassword($id : String!, $newpassword : String!) {
      changePassword(id: $id, newPassword: $newpassword) {
          success
          message
      }
  }`;
    const variables = {
      id: userId,
      newpassword: newpassword
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const { success, message } = response.data.data.changePassword;
      if (success) {
        setSuccMessage(ERROR_MESSAGES.SUCCESS);
        setModalOpen(true);
        setTimeout(() => {
          navigate('/')
        }, 3000);
      }
      else {
        setErrorMessage(message);
        setConfirmPassword('')
      }
      setVerificationMessage(ERROR_MESSAGES.SUCCESS);
    }
    catch (error) {
      console.error(ERROR_MESSAGES.ERROR, error);
    }
    setotp('');
  };
  const handleClose = () => {
    setModalOpen(false);
    navigate('/');
  };
  const customCloseIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} style={{ justifyContent: 'end', width: '200px' }}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <PheonixBox >
        <PheonixContainer maxWidth='xs'>
          <PheonixPaper
            elevation={3}
            style={{
              padding: '20px',
              height: '500px',
              marginLeft: '10px',
              width: '400PX',
              borderRadius: '0',
            }}
          >
            <Stack spacing={2} direction="column">
              <Item>
                <img src={logo} alt="Your Alt Text" style={{ width: '150px', height: '99px' }} />
              </Item>
              <Box component="form">
                <Item>
                  <Typography variant="h6" style={{ color: '#1161A0', fontFamily: 'Roboto', marginBottom: '10px', marginTop: '-5px' }}>{TEXT_MESSAGES.RESET_TEXT}</Typography>
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type={showPassword ? 'text' : 'password'}
                    value={newpassword}
                    placeholder="New Password"
                    label="New Password"
                    variant="outlined"
                    onChange={handlenewpasswordChange}
                    onFocus={handleNewFocus}
                    onBlur={handleNewBlur}
                    error={Boolean(errorMessage)}
                    helperText={errorMessage || ''}
                    style={{ width: '350px' }}
                    endAdornment={
                      <InputAdornment position="end" style={{ cursor: 'pointer', marginTop: '5px', marginRight: '20px',color:'black' }} onClick={handleTogglePassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </InputAdornment>
                    }
                  />
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type={showPassword1 ? 'text' : 'password'}
                    value={confirmPassword}
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    variant="outlined"
                    onChange={handleConfirmpasswordChange}
                    onFocus={handleConfirmFocus}
                    onBlur={handleConfirmBlur}
                    error={Boolean(errorMessage1)}
                    helperText={errorMessage1 || ''}
                    style={{ width: '350px' }}
                    endAdornment={
                      <InputAdornment position="end" style={{ cursor: 'pointer', marginTop: '5px', marginRight: '20px',color:'black' }} onClick={handleTogglePassword1}>
                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </InputAdornment>
                    }
                  />
                </Item>
                <Item>
                  <SubmitButton label={TEXT_MESSAGES.RESET_BUTTON} onClick={handlechangepassword} disabled={buttonDisable} />
                </Item>
              </Box>
            </Stack>
            <SnackBar
              open={modalOpen}
              onClose={handleClose}
              message={succMessage}
              vertical="top"
              horizontal="center"
              customAction={customCloseIcon}
            />
          </PheonixPaper>
        </PheonixContainer>
      </PheonixBox>
    </div>
  );
};
export default ForgotPassword;
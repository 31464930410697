import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
  TextField,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import Flatpickr from "react-flatpickr";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { api } from "../api";
import axios from "axios";
import { GET_ALL_CUSTOMERS } from "../graphql/query";
import PheonixTextField from "../Components/PheonixTextField";
import PheonixProductTable from "./PheonixProductTable";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RowData, TableColumn } from "../interfaces/PheonixProductTableprops";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";
import QuoteTableProps from "../interfaces/QuoteTableProps";
import QuoteData from "../interfaces/PheonixQuotation";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const QuoteTable: React.FC<QuoteTableProps> = ({
  QuotesData,
  setQuotesData,
  status,
  setStatus,
  QuotationDate,
  setQuotationDate,
  ExpiryDate,
  setExpiryDate,
  fieldErrors,
  setFieldErrors,
  customerOptions,
  setCustomerOptions,
  rows,
  setRows,
  tableFieldErrors,
  setTableFieldErrors,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [totalAmount, setTotalAmount] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState<string>("");

  const calculateAmount = (rows: any) => {
    return rows.reduce(
      (total: any, row: any) => total + (parseFloat(row.Rate || 0) * parseFloat(row.Quantity || 0)),
      0
    );
  };

  const calculateTotalAmount = (rows: any) => {
    return rows.reduce(
      (total: any, row: any) => total + parseFloat(row.Amount || 0),
      0
    );
  };

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  useEffect(() => {
    const totalAmount = calculateTotalAmount(rows);
    setTotalAmount(totalAmount);
    setQuotesData((prevState) => ({
      ...prevState,
      ["Amount"]: totalAmount,
    }));
  }, [rows]);

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleQuotationDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setQuotesData((prevState) => ({
      ...prevState,
      ["Quotation Date"]: formattedDate,
    }));
  };
  const handleExpiryDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setQuotesData((prevState) => ({
      ...prevState,
      ["Expiry Date"]: formattedDate,
    }));
  };

  const planOptions = ["IGST", "CGST + IGST"];
  const ProductPlanOptions = ["Plan 1", "Plan 2", "Plan 3"];

  const handleNameChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    const { id, name, email, number, gst_no, address } = newValue;
    setQuotesData((prevQuoteData) => ({
      ...prevQuoteData,
      "Customer Name": name,
      "Customer Id": id,
      Email: email,
      "Phone Number": number,
      "GST Number": gst_no,
      Address: address,
    }));
  };
  const handleGstChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setQuotesData((prevQuoteData) => ({
      ...prevQuoteData,
      "GST Type": newValue,
    }));
  };

  const handleproductChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setQuotesData((prevQuoteData) => ({
      ...prevQuoteData,
      "Product Plan": newValue,
    }));
  };

  const handleInputChange = (label: string, value: string) => {
    const newFormData: QuoteData = { ...QuotesData, [label]: value };
    switch (label) {
      case "Customer Name":
        if (!/^[a-zA-Z\s]*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.NAME_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "GST Number":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_ERROR,
          }));
        } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_ERR1,
          }));
        } else if (value.length !== 15) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;

      case "Address":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.ADD_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Quotation Notes":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.DESC_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Customer Notes":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.DESC_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "HSN Number":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.HSN_REQ,
          }));
        } else if (value.length > 8) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.HSN_ERROR,
          }));
        } else if (!/^[0-9]*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.HSN_VAL,
          }));
        } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.HSN_ERR1,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Product/Plan":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.PROD_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Phone Number":
        if (!/^[0-9]*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.NUMBER_ERROR,
          }));
        } else if (value.length !== 10) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.NUMBER_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Amount":
        if (!/^₹?\d*\.?\d*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.AMOUNT_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Email":
        if (!TEXT_MESSAGES.EMAIL_REG.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.MAIL_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      default:
        return true;
    }

    setQuotesData(newFormData);
  };

  const handleDeleteRow = (index: number) => {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      setRows(newRows);
      setTableFieldErrors((prevErrors: { [key: string]: any }) => {
        const newErrors = { ...prevErrors };
        if (newErrors[index]) {
          Object.keys(newErrors[index]).forEach((field) => {
            newErrors[index][field] = "";
          });
        }
        return newErrors;
      });
    }
  };
  const handleAddRow = () => {
    const newRow = {
      "Product Details": "",
      Quantity: "",
      Rate: "",
      Tax: "",
      Amount: "",
    };
    setRows([...rows, newRow]);
  };

  const handleInputChange1 = (
    index: number,
    fieldName: keyof (typeof rows)[0],
    value: string
  ) => {
    if (fieldName === "Product Details") {
      if (/[^\w\s]/.test(value)) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.BATCH_VAL,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    } else if (fieldName === "Rate") {
      const isValidNumber = /^\d*.?\d+$/.test(value) && parseFloat(value) >= 0;
      if (!isValidNumber) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.MRP_VAL,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    } else if (fieldName === "Quantity") {
      if (!/^\d+$/.test(value)) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.QUANTITY_VAL,
          },
        }));
      } else if (!value) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.QUANTITY_REQ,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    } else if (fieldName === "Amount") {
      const isValidNumber = /^\d*.?\d+$/.test(value) && parseFloat(value) >= 0;
      if (!isValidNumber) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.AMT_VAL,
          },
        }));
      } else if (!value) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.AMT_REQ,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    }

    const newRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });
    if (fieldName === "Rate" || fieldName === "Quantity") {
      const rate = parseFloat(newRows[index].Rate || "0");
      const quantity = parseFloat(newRows[index].Quantity || "0");
      const amount = (rate * quantity).toFixed(2); 
      newRows[index].Amount = amount.toString();
    }
    setRows(newRows);
  };

  const columns = [
    {
      field: "Product Details",
      headerName: "Product Details",
    },
    {
      field: "Quantity",
      headerName: "Quantity",
    },

    {
      field: "Rate",
      headerName: "Rate",
    },
    {
      field: "Tax",
      headerName: "Tax",
    },

    {
      field: "Amount",
      headerName: "Amount",
    },
    {
      field: "Action",
      headerName: "Action",
      render: (index: number) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAddRow()}
            style={{ color: "#181D8C" }}
          >
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteRow(index)}
            style={{ color: "#D32F2F" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Box>
      <table style={{ width: "100%", marginTop: "1%" }}>
        <tbody>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <PheonixTextField
                id="outlined-error"
                type="text"
                value={QuotesData["Quotation Notes"]}
                placeholder="Quotation Notes"
                label="Quotation Notes"
                error={!!fieldErrors?.["Quotation Notes"]}
                helperText={fieldErrors["Quotation Notes"]}
                name="Quotation Notes"
                variant="outlined"
                size="small"
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "309px" }}
              />
            </td>
            <td style={{ width: "25%" }}> </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Quotation Date"
                  format="DD/MM/YYYY"
                  onChange={handleQuotationDateChange}
                  disablePast
                  value={
                    QuotesData["Quotation Date"]
                      ? dayjs(QuotesData["Quotation Date"], "DD/MM/YYYY")
                      : null
                  }
                  sx={{
                    width: 309,
                  }}
                />
              </LocalizationProvider>
              <div style={{ marginLeft: "30px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Expiry Date"
                    format="DD/MM/YYYY"
                    onChange={handleExpiryDateChange}
                    disablePast
                    minDate={
                      QuotesData["Quotation Date"]
                        ? dayjs(QuotesData["Quotation Date"], "DD/MM/YYYY")
                        : dayjs()
                    }
                    value={
                      QuotesData["Expiry Date"]
                        ? dayjs(QuotesData["Expiry Date"], "DD/MM/YYYY")
                        : null
                    }
                    sx={{
                      width: 309,
                    }}
                  />
                </LocalizationProvider>
              </div>
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <Autocomplete
                disablePortal
                options={customerOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Customer Name" />
                )}
                disableClearable={true}
                getOptionLabel={(option) => option.customerNo ? `${option.name} (${option.customerNo})` : option.name}
                onChange={(event, newValue) =>
                  handleNameChange(event, newValue)
                }
                value={
                  customerOptions.find(
                    (customer) => customer.name === QuotesData["Customer Name"]
                  ) || { id: "", name: "",customerNo:"" }
                }
                style={{ width: "309px" }}
              />
              <div style={{ marginLeft: "30px" }}>
                <PheonixTextField
                  id="outlined-error"
                  type="text"
                  value={QuotesData["Phone Number"]}
                  placeholder="Phone Number"
                  name="Phone Number"
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  error={!!fieldErrors?.["Phone Number"]}
                  helperText={fieldErrors["Phone Number"]}
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  style={{ width: "309px" }}
                  readonly={true}
                />
              </div>
            </td>
            <td style={{ width: "25%", padding: "10px" }}>
              <PheonixTextField
                id="outlined-error"
                type="text"
                value={QuotesData["Email"]}
                placeholder="Email"
                label="Email"
                name="Email"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Email"]}
                helperText={fieldErrors["Email"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "309px" }}
                readonly={true}
              />
            </td>
            <td style={{ width: "25%" }}></td>
          </tr>

          <tr>
            <td
              colSpan={2}
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <PheonixTextField
                id="outlined-error"
                type="text"
                value={QuotesData["Address"]}
                placeholder="Address"
                label="Address"
                name="Address"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Address"]}
                helperText={fieldErrors["Address"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "652px" }}
                multiline={true}
                rows={2}
                readonly={true}
              />
            </td>
            <td style={{ padding: "10px" }}>
              <PheonixTextField
                id="outlined-error"
                type="text"
                value={QuotesData["HSN Number"]}
                placeholder="HSN Number"
                label="HSN Number"
                name="HSN Number"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["HSN Number"]}
                helperText={fieldErrors["HSN Number"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "309px" }}
              />
            </td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td colSpan={4}>
              <div style={{ marginLeft: "8px" }}>
                <Card>
                  <CardContent
                    style={{ backgroundColor: "lightgrey", height: "3px" }}
                  >
                    <div style={{ marginTop: "-10px" }}>Products</div>
                  </CardContent>
                  <div>
                    <PheonixProductTable
                      rows={rows}
                      columns={columns}
                      handleInputChange={handleInputChange1}
                      handleAddRow={handleAddRow}
                      handleDeleteRow={handleDeleteRow}
                      fieldErrors={tableFieldErrors}
                    />
                  </div>
                </Card>
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <PheonixTextField
                id="outlined-error"
                type="text"
                value={QuotesData["Amount"]}
                placeholder="Amount"
                label="Amount"
                name="Amount"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Amount"]}
                helperText={fieldErrors["Amount"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "309px" }}
              />
              <div style={{ marginLeft: "30px" }}>
                <Autocomplete
                  disablePortal
                  options={planOptions}
                  renderInput={(params) => (
                    <TextField {...params} label="GST Type" />
                  )}
                  onChange={(event, newValue) =>
                    handleGstChange(event, newValue)
                  }
                  value={QuotesData["GST Type"]}
                  style={{ width: "309px" }}
                />
              </div>
            </td>
            <td style={{ padding: "10px" }}>
              <PheonixTextField
                id="outlined-error"
                type="text"
                value={QuotesData["GST Number"]}
                placeholder="GST Number"
                label="GST Number"
                name="GST Number"
                variant="outlined"
                error={!!fieldErrors?.["GST Number"]}
                helperText={fieldErrors["GST Number"]}
                size="small"
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "309px" }}
              />
            </td>

            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <Autocomplete
                disablePortal
                options={ProductPlanOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Product Plan" />
                )}
                onChange={(event, newValue) =>
                  handleproductChange(event, newValue)
                }
                style={{ width: "309px" }}
                value={QuotesData["Product Plan"]}
              />
              <div style={{ marginLeft: "30px" }}>
                <FormControl sx={{ width: "309px" }}>
                  <InputLabel id="demo-select-small-label">Status</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={status}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                    <MenuItem value={"Accepted"}>Accepted</MenuItem>
                    <MenuItem value={"Rejected"}>Rejected</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>

          <tr>
            <td style={{ padding: "10px" }}>
              <PheonixTextField
                id="outlined-error"
                type="textarea"
                value={QuotesData["Customer Notes"]}
                placeholder="Customer Notes"
                name="Customer Notes"
                label="Customer Notes"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Customer Notes"]}
                helperText={fieldErrors["Customer Notes"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "652px" }}
                multiline={true}
                rows={2}
              />
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};
export default QuoteTable;

import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import PheonixSideBarProps from '../interfaces/PheonixSideBarProps';

const PheonixSideBar: React.FC<PheonixSideBarProps> = ({ icon, text, to }) => {
  const location = useLocation();
return (
    <ListItem
    sx={{
      textDecoration: 'none',
      backgroundColor: location.pathname === to ? 'white' : 'transparent',
      color: location.pathname === to ? '#181D8C' : 'inherit',
      borderTopLeftRadius: location.pathname === to ? '25px' : '0px',
      borderBottomLeftRadius: location.pathname === to ? '25px' : '0px',
      '&:hover': { backgroundColor: location.pathname === to ? 'white' : '#181D8C', textDecoration: 'none' },
      fontSize: '20px',
      '& .MuiTypography-root': {
        fontSize: '18px', 
        fontWeight:'500',
      },
    }}
      component={Link}
      to={to}
    >
      <ListItemIcon sx={{  color: location.pathname === to ? '#181D8C' : 'inherit'}} >{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};
export default PheonixSideBar;
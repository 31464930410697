import React, { ChangeEvent, useEffect, useState } from "react";
import PheonixProductTableProps from "../interfaces/PheonixProductTableprops";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Button,
} from "@mui/material";

const PheonixProductTable: React.FC<PheonixProductTableProps> = ({
  columns,
  rows,
  handleInputChange,
  handleAddRow,
  handleDeleteRow,
  fieldErrors,
}) => {
  const planOptions = ["Taxable", "No Tax"];
  const renderCell = (column: any, row: any, index: any) => {
    if (column.render) {
      return column.render(index);
    }

    if (
      column.field === "Rate" ||
      column.field === "Quantity" ||
      column.field === "Amount" ||
      column.field === "Product Details"
    ) {
      return (
        <TextField
          type="text"
          value={row[column.field] || ""}
          placeholder={
            column.field === "Rate" || column.field === "Amount"
              ? "0.00"
              : column.field === "Quantity"
              ? "0"
              : "Enter Product"
          }
          size="small"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange(index, column.field, e.target.value)
          }
          error={!!fieldErrors?.[index]?.[column.field]}
          helperText={fieldErrors?.[index]?.[column.field] || ""}
          sx={{
            width: "100%",
            padding: "4px",
            marginLeft:
              column.field === "Product Details" || column.field === "Amount"
                ? "-10%"
                : "-15%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
          inputProps={{
            style: {
              textAlign: column.field === "Product Details" ? "left" : "right",
            },
          }}
          InputProps={{
            style: { textAlign: "right" },
          }}
        />
      );
    }

    if (column.field === "Tax") {
      return (
        <Autocomplete
          disablePortal
          options={planOptions}
          size="small"
          renderInput={(params) => <TextField {...params} label="" />}
          onChange={(event, newValue) =>
            handleInputChange(index, column.field, newValue)
          }
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
            },
          }}
          value={row[column.field] || null}
          style={{ width: "150px", marginLeft: "5%" }}
        />
      );
    }

    return row[column.field];
  };
  return (
    <TableContainer>
      <Table>
        <div>
          <TableRow style={{ fontFamily: "inter" }}>
            {columns.map((column) => (
              <TableCell
                key={column.field}
                style={{
                  width: "20%",
                  padding: "10px",
                  fontSize: "16px",
                  color: "black",
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  marginLeft:
                    column.field === "Quantity" || column.field === "Rate"
                      ? "20%"
                      : "0%",
                  textAlign:
                    column.field === "Product Details" ? "left" : "center",
                }}
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </div>

        <div>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                style={{
                  color: "black",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    style={{
                      width: "20%",
                      fontFamily: "Roboto",
                      color: "black",
                    }}
                  >
                    {renderCell(column, row, index)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </div>
      </Table>
    </TableContainer>
  );
};

export default PheonixProductTable;

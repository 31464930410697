import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import DensityMediumOutlinedIcon from "@mui/icons-material/DensityMediumOutlined";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import PheonixDataGridProps from "../interfaces/PheonixGridProps";

const useStyles = makeStyles({
  virtualScrollerContent: {
    paddingTop: "20px",
  },
  toolbarIcon: {
    color: "white",
  },
  toolbarContainer: {
    backgroundColor: "#F6F6F9",
    padding: "10px",
    width: "400px",
    border: "0.5px solid #B7BAFF",
    borderRadius: "4px",
    marginTop: "10px",
  },
});

const CustomToolbar = () => {
  const classes = useStyles();

  return (
    <GridToolbarContainer className={classes.toolbarContainer}>
      <IconButton
        style={{
          fontSize: "16px",
          lineHeight: "19.36px",
          fontWeight: "500",
          color: "#181D8C",
          backgroundColor: "#F6F6F9",
          borderRadius: "0px",
          textTransform: "none",
        }}
        component={GridToolbarColumnsButton}
      >
        <ViewColumnOutlinedIcon />
      </IconButton>
      <IconButton
        style={{
          fontSize: "16px",
          lineHeight: "19.36px",
          fontWeight: "500",
          color: "#181D8C",
          backgroundColor: "#F6F6F9",
          borderRadius: "0px",
          textTransform: "none",
        }}
        component={GridToolbarFilterButton}
      >
        <FilterListOutlinedIcon />
      </IconButton>
      <IconButton
        style={{
          fontSize: "16px",
          lineHeight: "19.36px",
          fontWeight: "500",
          color: "#181D8C",
          backgroundColor: "#F6F6F9",
          borderRadius: "0px",
          textTransform: "none",
        }}
        component={GridToolbarDensitySelector}
      >
        <DensityMediumOutlinedIcon />
      </IconButton>
    </GridToolbarContainer>
  );
};

const PheonixDataGrid: React.FC<PheonixDataGridProps> = ({
  rows,
  columns,
  components,
  style,
  pageSizeOptions,
  paginationModel,
  rowCount,
  onPaginationModelChange,
  paginationMode,
  ...props
}) => {
  const classes = useStyles();

  const getRowClassName = (params: any) => {
    return params.index % 2 === 0 ? "even-row" : "odd-row";
  };

  return (
    <div style={{ width: "100%",overflow:'auto' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
          ...components,
        }}
        paginationModel={paginationModel}
        rowCount={rowCount}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={pageSizeOptions || [5, 10, 20, 30]}
        style={style}
        getRowClassName={getRowClassName}
        classes={{ virtualScrollerContent: classes.virtualScrollerContent }}
        disableRowSelectionOnClick
        componentsProps={{
          cell: {
            style: {
              outline: "none",
            },
          },
          row: {
            style: {
              outline: "none",
            },
          },
        }}
        {...props}
      />
    </div>
  );
};
export default PheonixDataGrid;

import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  IconButton,
  AppBar,
  Typography,
  Modal,
  Stack,
  styled,
  Button,
  Container,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { api } from "../api";
import { ADD_CUSTOMER_MUTATION } from "../graphql/mutation";
import Switch from "@mui/material/Switch";
import PheonixCustomerModalProps from "../interfaces/PheonixCustomerModalProps";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PheonixTextField from "./PheonixTextField";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PheonixButton from "./PheonixButton";
const Item = styled("div")({
  marginBottom: "15px",
  padding: "8px",
  textAlign: "center",
});

const PheonixCustomerModal = <T extends object>({
  open,
  edit,
  onClose,
  onConfirm,
  fields,
  formData,
  setFormData,
  handleConfirm,
  switchSelected,
  setSwitchSelected,
}: PheonixCustomerModalProps<T>) => {
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    setFieldErrors({});
  }, [open]);

  useEffect(() => {
    validateForm();
  }, [formData]);

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setIsConfirmDisabled(hasErrors);
  }, [fieldErrors]);

  const validateForm = () => {
    let isAnyFieldEmpty = false;
    fields.forEach((field) => {
      const fieldValue = (formData as { [key: string]: string })[field.label];
      if (!fieldValue) {
        isAnyFieldEmpty = true;
      } else {
        validateField(field.label);
      }
    });
    setIsButtonDisabled(isAnyFieldEmpty);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setSwitchSelected(isChecked);
  };

  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
  };

  const handleFieldBlur = (fieldLabel: string) => {
    validateField(fieldLabel);
  };
  const validateField = (fieldLabel: string) => {
    const fieldValue = (formData as { [key: string]: string })[fieldLabel];
    switch (fieldLabel) {
      case "Company Name":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.COMPANY_REQ,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Contact Number":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NUMBER_REQ,
          }));
        } else if (!/^[0-9]*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NUMBER_ERROR,
          }));
        } else if (fieldValue.length !== 10) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NUMBER_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Contact Email ID":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MAIL_REQ,
          }));
        } else if (!TEXT_MESSAGES.EMAIL_REG.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MAIL_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Customer Full Name":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NAME_REQ,
          }));
        } else if (!/^[a-zA-Z\s]*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NAME_VALID,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "GST Number":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.GST_ERROR,
          }));
        } else if (!/^[a-zA-Z0-9]+$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.GST_ERR1,
          }));
        } else if (fieldValue.length !== 15) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.GST_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Address":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.ADD_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          height: "100vh",
          width: 351,
          boxShadow: 24,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5%",
          }}
        >
          <div
            style={{
              width: "38px",
              height: "38px",
              backgroundColor: "#EFEFFF",
              borderRadius: "15px",
              display: "flex",
              alignSelf: "flex-start",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "5%",
            }}
          >
            <PersonAddAltOutlinedIcon />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: "2%" }}
          >
            <Typography style={{ fontSize: "16px" }}>
              {edit ? TEXT_MESSAGES.EDIT_Customer : TEXT_MESSAGES.ADD_CUSTOMER}
            </Typography>
          </div>
          <IconButton
            onClick={onClose}
            style={{
              position: "absolute",
              right: "15px",
              width: " 16px",
              height: " 16px",
              padding: "5px",
              color: "#5B616B",
              marginTop: "2%",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#CBC2E9",
            marginTop: "5%",
          }}
        ></div>
        <div style={{ maxHeight: "600px", overflowY: "auto" }}>
          <Stack spacing={1} direction="column">
            {fields.map((field, index) => (
              <Item>
                <PheonixTextField
                  id="outlined-error"
                  type="text"
                  placeholder={field.label}
                  label={field.label}
                  value={(formData as { [key: string]: string })[field.label]}
                  error={!!fieldErrors[field.label]}
                  helperText={fieldErrors[field.label]}
                  onBlur={() => handleFieldBlur(field.label)}
                  onChange={(e) =>
                    handleFieldChange(field.label, e.target.value)
                  }
                  variant="outlined"
                  style={{ width: "319px" }}
                  multiline={(field.label === 'Address')? true : false}
                  rows={2}
                />
              </Item>
            ))}
          </Stack>

          <FormGroup
            aria-label="position"
            row
            style={{ marginLeft: "2%", marginTop: "10%" }}
          >
            <FormControlLabel
              value="end"
              control={<Switch color="primary" onChange={handleSwitchChange} />}
              label="Invite mail notification"
              labelPlacement="end"
              style={{ marginLeft: "0%", marginTop: "-8%" }}
              checked={switchSelected}
            />
          </FormGroup>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignSelf: "flex-end",
            marginTop: "auto",
            flexDirection: "column",
          }}
        >
          <div
            style={{ height: "2px", width: "100%", backgroundColor: "#CBC2E9" }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Button
              onClick={onClose}
              style={{
                textTransform:'none',
                color: "#181D8C",
                backgroundColor: "white",
                border: "1px solid #181D8C",
                width: "125px",
                height: "36px",
                borderRadius: "5px",
                marginLeft: "2%",
              }}
            >
              {TEXT_MESSAGES.CANCEL}
            </Button>
            <Button
              disabled={isButtonDisabled || isConfirmDisabled}
              onClick={handleConfirm}
              sx={{
                textTransform:'none',
                color: "white",
                border: "1px solid",
                backgroundColor:
                  isButtonDisabled || isConfirmDisabled
                    ? "lightgray"
                    : "#181D8C",
                width: "125px",
                height: "36px",
                borderRadius: "5px",
                marginRight: "2%",
                "&:hover": { backgroundColor: "#181D8C" },
                "&:active": { backgroundColor: "#181D8C" },
              }}
            >
              {edit ? TEXT_MESSAGES.UPDATE_TXT : TEXT_MESSAGES.SAVE}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default PheonixCustomerModal;

import React, { ChangeEvent, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PheonixTextField from '../Components/PheonixTextField';
import SubmitButton from '../Components/PheonixButton';
import logo from '../assets/logo.jpg';
import PheonixPaper from '../Components/PheonixPaper';
import PheonixBox from '../Components/PheonixBox';
import PheonixContainer from '../Components/PheonixContainer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container, styled } from '@mui/material';
import SnackBar from '../Components/PheonixSnackBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import axios from 'axios';
import SyncIcon from '@mui/icons-material/Sync';
import { api } from '../api';
import { ERROR_MESSAGES, TEXT_MESSAGES } from '../const';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSignUpContext } from '../Contexts/SignUpContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const Item = styled('div')({
  marginBottom: '10px',
  padding: '8px',
  textAlign: 'center',
});
const VerifyOTP: React.FC = () => {
const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessage1, setErrorMessage1] = useState('');
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState('');
  const { emailId } = useSignUpContext();
  const [isRotating, setIsRotating] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(30);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const cameFromLogin = sessionStorage.getItem('cameFromReq');
    if (!cameFromLogin) {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    sessionStorage.removeItem('cameFromReq');
  }, []);
  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const handleback = (e: { preventDefault: () => void; }) => {
    e.preventDefault(); 
    sessionStorage.setItem('cameFromVerify', 'true');
    navigate('/requestotp'); 
  };

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (resendDisabled && timer > 0) {
      countdown = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendDisabled(false);
      setTimer(30);
    }

    return () => clearInterval(countdown);
  }, [resendDisabled, timer]);

useEffect(() => {
    if (!otp) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [otp]);
 const handleOtpFocus = async () => {
    setErrorMessage1('');
    setErrorMessage('');
  };
  const handleOtpBlur = () => {
  };
const handleClose = () => {
    setModalOpen(false);
  };
 const customCloseIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} style={{ justifyContent: 'end', width: '200px' }}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
const handleverifyotp = async () => {
    if (!otp) {
      setErrorMessage(ERROR_MESSAGES.ENTER_ALL);
      return;
    }
 const query = `
    mutation VerifyOtp($otp : String!){
        verifyOtp(otp : $otp){
        success
        message 
        }
    }
    `;
    const variables = {
      otp: otp,
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const { success, message } = response.data.data.verifyOtp;
      if (success) {
        setSuccMessage(TEXT_MESSAGES.OTP_VERIFY);
        setModalOpen(true);
        setTimeout(() => {
          sessionStorage.setItem('cameFromVerify', 'true');
          navigate('/forgotpassword')
        }, 3000);
      } else {
        setErrorMessage(message);
      }
      setVerificationMessage(ERROR_MESSAGES.CHECK_MAIL);
    }
    catch (error) {
      console.error(ERROR_MESSAGES.ERROR, error);
    }
    setOtp('');
  };
  const handlerequestotp = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsRotating(true);
    setResendDisabled(true);
    const emailId =localStorage.getItem("emailid");
    const query = `
    mutation RequestOtp($email : String!){
        requestOtp(email : $email){
        success
        message 
        }
    }
    `;
    const variables = {
      email: emailId,

    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,

      });

      const { success, message } = response.data.data.requestOtp;
      if (success) {
        setSuccMessage(TEXT_MESSAGES.OTP_SENT);
        setModalOpen(true);
        setTimeout(() => {
          setModalOpen(false);
        }, 3000);
        setResendDisabled(true);
} else {
        setErrorMessage(message);
      }
      setVerificationMessage(ERROR_MESSAGES.CHECK_MAIL);

    }
    catch (error) {
      console.error(ERROR_MESSAGES.ERROR, error);
      setErrorMessage(ERROR_MESSAGES.ERROR);
    }
  };
 return (
    <div style={{backgroundColor:"#F7F7F7"}}>
    <PheonixBox >
      <PheonixContainer maxWidth='xs'>
        <PheonixPaper
          elevation={3}
          style={{
            padding: '20px',
            height: '400px',
            marginLeft: '10px',
            width: '400PX',
            borderRadius: '0',
          }}
        >
          <Stack spacing={2} direction="column">
            <Item>
              <img src={logo} alt="Your Alt Text" style={{ width: '150px', height: '99px' }} />
            </Item>
            <Box component="form">
              <Item>
                <Typography variant="h6" style={{ color: '#1161A0', fontFamily: 'Roboto', marginBottom: '10px', marginTop: '-5px' }}>{TEXT_MESSAGES.FORGOT_LABEL}</Typography>
              </Item>
              <Item>
                <PheonixTextField
                  id="outlined-error"
                  type="text"
                  value={otp}
                  onChange={handleOtpChange}
                  onFocus={handleOtpFocus}
                  onBlur={handleOtpBlur}
                  error={Boolean(errorMessage)}
                  helperText={errorMessage || ''}
                  label="OTP"
                  variant="outlined"
                  style={{ width: "350px", }}
                />
              </Item>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={handleback}
                  style={{ fontSize: '16px', color: '#2196F3', textDecoration: 'none' }} >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography 
                    onClick={handleback}
                    variant="body1" 
                    style={{ fontSize: '16px', color: '#2196F3', textDecoration: 'none', marginLeft: '-1px' }}
                  >
                    Back
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton 
                    style={{ fontSize: '16px', color: '#2196F3', textDecoration: 'none' }} 
                    disabled={resendDisabled}
                  >
                    <SyncIcon className={isRotating ? 'rotate' : ''} />
                  </IconButton>
                  <Typography 
                    onClick={handlerequestotp}
                    variant="body1" 
                    style={{ fontSize: '16px', color: '#2196F3', textDecoration: 'none', marginLeft: '-10px' }}
                  >
                    {resendDisabled ? `${timer} s` : TEXT_MESSAGES.RESEND_TEXT}
                  </Typography>
                </Box>
              </Box>
              
              <Item >
                <SubmitButton label={TEXT_MESSAGES.VERIFY_BUTTON} onClick={handleverifyotp} disabled={buttonEnabled} />
              </Item>
            </Box>
 </Stack>
          <SnackBar
            open={modalOpen}
            onClose={handleClose}
            message={succMessage}
            vertical="top"
            horizontal="center"
            customAction={customCloseIcon}
          />
        </PheonixPaper>
      </PheonixContainer>
    </PheonixBox>
</div>
  );
};
export default VerifyOTP;
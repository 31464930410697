import React, { useState, useEffect } from "react";
import SearchComponent from "../Components/PheonixSearch";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import { api } from "../api";
import axios from "axios";
import Quote from "../interfaces/Quote";
import PheonixPaper from "../Components/PheonixPaper";
import Popover from "@mui/material/Popover";
import PheonixButton from "../Components/PheonixButton";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSignUpContext } from "../Contexts/SignUpContext";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import PheonixChip from "../Components/PheonixChip";
import PheonixModal from "../Components/PheonixModal";
import { TEXT_MESSAGES } from "../const";
import more from "../assets/Vector.png";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import NewQuotation from '../Components/NewQuotation';
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const QuotesInPaper: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useSignUpContext();
  const [open, setOpen] = React.useState(false);
  const [quotationsData, setQuotationsData] = useState<Quote[]>([]);
  const [filteredQuotationsData, setFilteredQuotationsData] = useState<Quote[]>(
    []
  );
  const [error, setError] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedQuote, setSelectedQuote] = useState<Quote | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quote, setQuote] = useState<Quote | null>(null);
  const [serialNumberCounter, setSerialNumberCounter] = useState(1);
  const [isQuotationOpen, setIsQuotationOpen] = useState(false);
  const [editid, setEditId] = useState("");

  const [containerEl, setContainerEl] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState<string>("");

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const handleClick = (e: any, id: any) => {
    setEditId(id);
    setContainerEl(e.currentTarget);
  };

  const handleClosePop = () => {
    setContainerEl(null);
  };

  const open1 = Boolean(containerEl);
  const id1 = open1 ? "simple-popover" : undefined;
  useEffect(() => {
    fetchQuotationData();
  }, [paginationModel.page, paginationModel.pageSize]);
  const fetchQuotationData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const usertype = localStorage.getItem("usertype");
      if (usertype === "1") {
        const response = await axios.post(api.baseUrl, {
          query: `
            query GetQuotationbyUser($userId: ID!, $page: Int!, $perPage: Int!) {
              getQuotationbyUser(user_id: $userId, page: $page, perPage: $perPage) {
                totalPages
                totalCount
                quotations {
                  id
                  serial_no
                  user_id
                  name
                  quotationid
                  quotationdate
                  productOrService {
                      name
                      tax
                      amount
                      rate
                      quantity
                    }
                  productplan
                  duedate
                  description
                  quotationnotes
                  subtotal
                  total
                  customerName
                  hsnCode
                  customerId
                  gstNumber
                  gstType
                  phoneNumber
                  email
                  address
                  price
                  status
                }
              }
            }
          `,
          variables: {
            userId: userId,
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
          },
        });
        const responseDataWithIndex =
          response.data.data.getQuotationbyUser.quotations.map(
            (item: {}, index: number) => ({
              ...item,
              serial_no: (index + 1).toString(),
            })
          );

        setQuotationsData(responseDataWithIndex);
        setTotalPages(response.data.data.getQuotationbyUser.totalCount);
      } else {
        const response = await axios.post(api.baseUrl, {
          query: `
            query GetQuotationbyUserType($customerId: ID!, $page: Int!, $perPage: Int!) {
              getQuotationbyUserType(customerId: $customerId, page: $page, perPage: $perPage) {
                totalPages
                totalCount
                quotations {
                  id
                  serial_no
                  user_id
                  name
                  quotationid
                  quotationdate
                   productOrService {
                    name
                    tax
                    amount
                    rate
                    quantity
                  }
                  productplan
                  duedate
                  description
                  quotationnotes
                  subtotal
                  total
                  customerName
                  customerId
                  gstNumber
                  hsnCode
                  gstType
                  phoneNumber
                  email
                  address
                  price
                  status
                }
              }
            }
          `,
          variables: {
            customerId: userId,
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
          },
        });
        const responseDataWithIndex =
          response.data.data.getQuotationbyUserType.quotations.map(
            (item: {}, index: number) => ({
              ...item,
              serial_no: (index + 1).toString(),
            })
          );

        setQuotationsData(responseDataWithIndex);
        setTotalPages(response.data.data.getQuotationbyUserType.totalCount);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };
  const handleRejectQuotation = async (quotation: Quote) => {
    try {
      const quotationId = quotation.id;
      const response = await axios.post(api.baseUrl, {
        query: `
                  mutation RejectQuotation($quotationId: ID!) {
                      rejectQuotation(_id: $quotationId) {
                          id
                          serial_no
                          user_id
                          name
                          quotationid
                          quotationdate
                          productOrService {
                    name
                    tax
                    amount
                    rate
                    quantity
                  }
                          duedate
                          description
                          subtotal
                          total
                          address
                          price
                          status
                      }
                  }
              `,
        variables: {
          quotationId: quotationId,
        },
      });
      setIsModalOpen(false);
      fetchQuotationData();
    } catch (error) {
      console.error(TEXT_MESSAGES.ERR_REJECT, error);
    }
  };
  const handleAccept = async (quotation: Quote) => {
    try {
      const quotationId = quotation.id;
      const response = await axios.post(api.baseUrl, {
        query: `
                  mutation acceptQuotation($quotationId: ID!) {
                      acceptQuotation(_id: $quotationId) {
                          id
                          serial_no
                          user_id
                          name
                          quotationid
                          quotationdate
                          productOrService {
                    name
                    tax
                    amount
                    rate
                    quantity
                  }
                          duedate
                          description
                          subtotal
                          total
                          address
                          price
                          status
                      }
                  }
              `,
        variables: {
          quotationId: quotationId,
        },
      });
      fetchQuotationData();
      setIsModalOpen(false);
    } catch (error) {
      console.error(TEXT_MESSAGES.ERR_ACCEPT, error);
    }
  };
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "#9e9e9e";
      case "expired":
        return "#9e9e9e";
      case "rejected":
        return "#ff5722";
      case "accepted":
        return "#4caf50";
      default:
        return "default";
    }
  };

  const handleOpenModal = (quotation: Quote) => {
    setSelectedQuote(quotation);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
      navigate("/NewQuotation");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const columns = [
    { field: "quotationdate", headerName: "Date", width: 150 },
    {
      field: "quotationid",
      headerName: "Quotation Number",
      width: 250,
      renderCell: (params: { row: Quote }) => {
        return (
          <Typography
            onClick={() => handleViewClick(params.row)}
            style={{
              color: "#181D8C",
              cursor: "pointer",
              textDecoration: "underline",
              font: "inherit",
            }}
          >
            {params.row.quotationid}
          </Typography>
        );
      },
    },
    { field: "customerName", headerName: "Customer Name", width: 230 },
    {
      field: "status",
      headerName: "Status",
      width: 175,
      renderCell: (params: { value: any }) => {
        const status = params.value;
        const iconColor = getStatusColor(status);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon style={{ color: iconColor, marginRight: "5px" }} />
            {status}
          </div>
        );
      },
    },

    {
      field: "price",
      headerName: "Amount",
      width: 160,
      renderCell: (params: any) => `₹ ${params.value}`,
    },
  ];

  const handleSearch = (searchQuery: string) => {
    const filteredData = quotationsData.filter((quotation) =>
      Object.values(quotation).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredQuotationsData(filteredData);
  };

  const handleViewClick = (row: Quote) => {
    if (loggedInUser === "1") {
      const editQuote = quotationsData.find(
        (obj) => obj.id.toString() === row.id.toString()
      );
      navigate("/NewQuotation", { state: { editQuote } });
    } else {
      handleOpenModal(row);
      setIsModalOpen(true);
    }
  };
  const handleDeleteClick = () => {
    setContainerEl(null);
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "84vh",
        padding: "20px",
        maxWidth: isSidebarOpen ? "calc(100% - 70px)" : "calc(100% - 80px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontFamily: "Roboto",
            color: "#181D8C",
            fontWeight: 600,
            textAlign: "center",
            lineHeight: "21px",
          }}
        >
          {TEXT_MESSAGES.QUOTATION}
        </Typography>
        <div style={{ marginRight: "-10px" }}>
          {loggedInUser === "1" && (
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                border: "1px solid #1C1B1F",
                backgroundColor: "#181D8C",
              }}
              onClick={handleOpen}
              label={TEXT_MESSAGES.NEW_QUOTATION}
            ></PheonixButton>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={
            filteredQuotationsData.length > 0
              ? filteredQuotationsData
              : quotationsData
          }
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
        />
      </div>
      {isModalOpen && selectedQuote && (
        <PheonixModal
          quote={selectedQuote}
          onClose={handleCloseModal}
          handleAccept={handleAccept}
          rejectquote={handleRejectQuotation}
        />
      )}
    </div>
  );
};

export default QuotesInPaper;
